export default {
  'menu.learn': 'Learn',
  'menu.education': 'Education',
  'menu.tutorials': 'Tutorials',
  'menu.courses': 'Courses',

  'menu.build': 'Build',
  'menu.studio': 'Studio',
  'menu.ext': 'Extensions',

  'menu.eco': 'Ecosystem',
  'menu.events': 'Events',
  'menu.bootcamp': 'Bootcamp',
  'menu.hackathon': 'Hackathon',
  'menu.workshop': 'Workshop',
  'menu.summit': 'Summit',

  'menu.community': 'Community',
  'menu.forum': 'Forum',
  'menu.discord': 'Discord',
  'menu.twitter': 'Twitter',
  'menu.telegram': 'Telegram',
  'menu.facebook': 'Facebook',

  'menu.resources': 'Resources',
  'menu.blog': 'Blog',
  'menu.guides': 'Guides',
  'menu.docs': 'Docs',
  'menu.videos': 'Videos',
  'menu.osc': 'Open Source Code',

  'menu.company': 'Company',
  'menu.about': 'About Us',
  'menu.contact': 'Contact Us',

  'menu.languages': 'Languages',
  'menu.languages.en': 'English',
  'menu.languages.zh': '简体中文'
};
