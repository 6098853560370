import cn from 'classnames';
import React, { ReactNode } from 'react';

import { ConnectWallet } from '@/components/pages/2048/ConnectWallet';
import { useAppStore } from '@/store/appStore';
interface IModalLayoutProps {
  className?: string;
  children: ReactNode;
}
export function ModalLayout(props: IModalLayoutProps) {
  const { className, children } = props;
  const { showLoginModal, setShowLoginModal } = useAppStore();
  return (
    <div className={cn(className)}>
      {showLoginModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.55)]">
          <ConnectWallet
            onClose={() => {
              setShowLoginModal(false);
            }}
          />
        </div>
      )}
      {children}
    </div>
  );
}
