import { ERarity } from './const';

export async function waitTime(time = 3000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, time);
  });
}

export function ellipseAddress(address?: string) {
  if (!address || address.length < 12) {
    return address || '';
  }
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
}

export function getRarityByTokenId(id: number) {
  if (id < 1200) {
    return ERarity.N;
  } else if (id < 1200 + 600) {
    return ERarity.R;
  } else if (id < 1200 + 600 + 140) {
    return ERarity.SR;
  } else {
    return ERarity.SSR;
  }
}
