export default {
  'home.first.projects': '合作项目',
  'home.first.contracts': '智能合约编译',
  'home.first.developers': '开发者',
  'home.first.increase': '集成的区块链数量',

  'home.intro.title': '与ChainIDE一起蓬勃发展',
  'home.intro.learn.title': '学习',
  'home.intro.learn.desc':
    'ChainIDE为各个技能水平提供定制的Web3课程，无论您是初学者还是有经验的开发者，都能找到适合您需求的完美课程。',
  'home.intro.build.title': '构建',
  'home.intro.build.desc':
    'OpenAI助力ChainIDE，优化多链智能合约和DApp的开发方式，轻松开始编码吧！',
  'home.intro.join.title': '加入',
  'home.intro.join.desc':
    'ChainIDE致力于构建和扩展全球开发者生态系统，立即加入我们，与全球开发者即时连接。',

  'home.edu.title': 'ChainIDE Education',
  'home.edu.desc': '学习工具',
  'home.edu.conversation.title': '对话教学',
  'home.edu.conversation.desc':
    '我们以创意的对话形式呈现所有的教程，使学习变得像聊天一样简单。',
  'home.edu.split.title': '分屏学习',
  'home.edu.split.desc': 'ChainIDE通过一体化屏幕彻底改变了学习方式。',
  'home.edu.video.title': '视频课程',
  'home.edu.video.desc': 'ChainIDE提供互动视频课程以进行编码指导。',
  'home.edu.together.title': '共同学习',
  'home.edu.together.desc': '支持查看与您一同学习的同学数量及头像。',
  'home.edu.button.text': '即刻学习',

  'home.studio.title': 'ChainIDE Studio',
  'home.studio.desc': '编译工具',
  'home.studio.ai.title': 'AI赋能编码',
  'home.studio.ai.desc':
    'ChainIDE Code Sage，智能编码AI，通过回答与代码相关的问题，提高编码效率和用户体验。',
  'home.studio.code.title': '全栈Dapp开发平台',
  'home.studio.code.desc':
    'ChainIDE为全栈开发者提供了在线一键编写、调试、测试和部署Dapp的能力，无需额外工具。',
  'home.studio.multi.title': '云端多链集成开发环境',
  'home.studio.multi.desc':
    'ChainIDE充分利用云端技术，实现了跨多个区块链的轻松部署，让你无需任何繁琐的设置即可畅享云端编译和迅速启动的便捷体验。',
  'home.studio.plugin.title': '插件兼容VSCode',
  'home.studio.plugin.desc':
    'ChainIDE提供涵盖格式化、代码检测、安全等多个领域的丰富插件，同时兼容VSCode的插件系统。',
  'home.studio.button.text': '即刻编译',

  'home.events.title': 'ChainIDE Events',
  'home.events.desc': '加入我们',
  'home.events.bootcamp.title': '学习营 & 黑客松',
  'home.events.bootcamp.desc':
    '学习营&黑客松致力于打造一个全球区块链开发者的技术交流平台，帮助开发者实现从入门到高级的突破。',
  'home.events.workshop.title': '工作坊与研讨会',
  'home.events.workshop.desc':
    '我们的工作坊与研讨会旨在普及区块链知识，与行业内部分享区块链的发展趋势，推动公众参与区块链生态系统的创新。',
  'home.events.summit.title': '开发者峰会',
  'home.events.summit.desc':
    '开发者峰会是我们团队与全球科技社区进行深度交流和知识交流的平台。',
  'home.events.button.text': '即刻加入',

  'home.future.title': '未来愿景',
  'home.future.desc': '团队目标',
  'home.future.membership.title': '高级会员计划',
  'home.future.membership.desc':
    '会员计划提供增值功能、折扣费用和优先支持，以帮助用户获得更卓越的使用体验。',
  'home.future.marketplace.title': 'ChainIDE 插件市场',
  'home.future.marketplace.desc':
    'ChainIDE插件市场提供多样化的插件和模板，优化您的开发体验。',
  'home.future.dao.title': 'ChainIDE DAO',
  'home.future.dao.desc':
    'ChainIDE DAO是负责监督ChainIDE的开发和运营的去中心化自治组织。',
  'home.future.bounty.title': 'ChainIDE 赏金计划',
  'home.future.bounty.desc':
    'ChainIDE赏金计划奖励开发者发现和报告平台问题、漏洞和安全漏洞。',

  'home.stories.title': '成功案例',
  'home.stories.desc': '团队成就',
  'home.stories.mw.title': 'Matrix World',
  'home.stories.mw.desc':
    'Matrix World是元宇宙的产物，用户可以创造、连接、探索，他们将拥有前所未有的体验。这个开放的世界允许用户在多个不同的区块链上构建沉浸式的3D应用程序。',
  'home.stories.mm.title': 'Matrix Market ',
  'home.stories.mm.desc':
    'Matrix Market是首个Flow区块链上为创作者、收藏家和交易者提供一体化市场的平台。',
  'home.stories.pb.title': 'PhantaBear',
  'home.stories.pb.desc':
    'PhantaBear项目是周杰伦旗下品牌PHANTACi与Ezek联合发起的。PhantaBear是一个由10000个算法生成的数字收藏品的集合，这些收藏品兼作Ezek Club的会员卡。每只PhantaBear都有一套独特的特征，并为主人解锁不同的、独特的访问权限和福利。',
  'home.stories.rm.title': 'Rivermen',
  'home.stories.rm.desc':
    'Rivermen是一个基于区块链技术的东方网络主题公园，由Cthuwork&NA团队推出，使用了“清明上河图”像素版本的元素。每个独特的Rivermen NFT都是一个随机生成的3D角色模型。',
  'home.stories.ts.title': 'Theirsverse',
  'home.stories.ts.desc':
    'Theirsverse已经打造了一个连接Web 2和Web 3的品牌。从NFT艺术品到时尚玩具和直销美妆品牌，Theirsverse愿意邀请全球创作者一起共创我们的品牌。',
  'home.stories.gld.title': 'GrandLineDAO',
  'home.stories.gld.desc': `觉醒！
突破界限！
跨越磨难！
共同梦想！
让我们驾驭极化之海！
  `,

  'home.partners.title': '合作伙伴'
};
