// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as basicErrorController from './basicErrorController';
import * as verifierController from './verifierController';
export default {
  basicErrorController,
  verifierController,
};
