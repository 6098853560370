import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '@/utils/hooks';
interface ISeoProps {
  title?: string;
  description?: string;
  url?: string;
}
export function Seo(props: ISeoProps) {
  const { title, description, url = '' } = props;

  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
    twitterSummaryLargeImage
  } = useSiteMetadata();

  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${url}`,
    twitterUsername,
    summaryLargeImage: `${siteUrl}${twitterSummaryLargeImage}`
  };
  return (
    <Helmet
      title={seo.title}
      meta={[
        {
          name: 'description',
          content: seo.description
        },
        {
          name: 'viewport',
          content:
            'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'
        },
        {
          property: `og:title`,
          content: seo.title
        },
        {
          property: `og:description`,
          content: seo.description
        },
        {
          property: `og:image`,
          content: seo.image
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:title`,
          content: seo.title
        },
        {
          name: `twitter:description`,
          content: seo.description
        },
        {
          name: `twitter:image`,
          content: seo.image
        },
        {
          name: 'twitter:creator',
          content: seo.twitterUsername
        },
        { name: 'twitter:url', content: seo.url }
      ]}
    />
  );
}
