import cn from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';

import { Button } from '@/components/common/Button';
import { useAppStore } from '@/store/appStore';
import { ERouterPaths } from '@/utils/const';
interface IGameBtnProps {
  className?: string;
}
export function GameBtns(props: IGameBtnProps) {
  const { className } = props;
  const { setGameMoves, setGameSeed, setGameSeedSignerAddr } = useAppStore();
  return (
    <div
      className={cn(
        className,
        'mb-2 flex w-[calc(100vw_-_32px)] max-w-[533px] items-center gap-x-[4.6%]'
      )}
    >
      <Button
        className="h-12 w-[46.8%] sm:!text-xl"
        onClick={() => {
          setGameMoves([]);
        }}
      >
        Try Again
      </Button>
      <Button
        onClick={() => {
          setGameSeed(undefined);
          setGameSeedSignerAddr(undefined);
          setGameMoves([]);
          // navigate(ERouterPaths.LEARN_MORE);
        }}
        className="h-12 w-[46.8%] !bg-[linear-gradient(92deg,#364254_0.69%,#364254_62%)] hover:!opacity-90 sm:!text-xl"
      >
        Exit
      </Button>
    </div>
  );
}
