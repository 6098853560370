export default {
  'menu.learn': '学习',
  'menu.education': '教育',
  'menu.tutorials': '指南',
  'menu.courses': '课程',

  'menu.build': '构建',
  'menu.studio': '云端IDE',
  'menu.ext': '插件市场',

  'menu.eco': '生态',
  'menu.events': '活动',
  'menu.bootcamp': '训练营',
  'menu.hackathon': '黑客松',
  'menu.workshop': '研讨会',
  'menu.summit': '峰会',

  'menu.community': '社区',
  'menu.forum': '论坛',
  'menu.discord': 'Discord',
  'menu.twitter': 'Twitter',
  'menu.telegram': 'Telegram',
  'menu.facebook': 'Facebook',

  'menu.resources': '资源',
  'menu.blog': '博客',
  'menu.guides': '指南',
  'menu.docs': '文档',
  'menu.videos': '视频',
  'menu.osc': '开源代码',

  'menu.company': '公司简介',
  'menu.about': '关于我们',
  'menu.contact': '联系我们',

  'menu.languages': '语言',
  'menu.languages.en': 'English',
  'menu.languages.zh': '简体中文'
};
