import cn from 'classnames';
import { navigate, type PageProps } from 'gatsby';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSearchParam } from 'react-use';
import { useAccount } from 'wagmi';

import {
  ConnectWallet,
  GamePlay,
  MintModal,
  PlayConfirmModal,
  Seo
} from '@/components';
import { useAppStore } from '@/store/appStore';
import { ERouterPaths } from '@/utils/const';

export enum EMintType {
  'DIRECTLY' = 'directly',
  'PlAY' = 'play'
}
export const MintTypePSearchParamKey = 'mintType';

export function goTo2048GamePage(type: EMintType) {
  navigate(`${ERouterPaths.GAME_2048}?${MintTypePSearchParamKey}=${type}`);
}

const IndexPage: React.FC<PageProps> = () => {
  const mintType = useSearchParam(MintTypePSearchParamKey);
  const { isConnected } = useAccount();
  const { playMintSignature, setPlayMintSignature } = useAppStore();
  const showPlayMintModal = useMemo(
    () => !!playMintSignature,
    [playMintSignature]
  );
  const {
    gameSeed,
    gameSeedSignerAddr,
    setGameSeed,
    setGameSeedSignerAddr,
    setGameMoves
  } = useAppStore();
  const { address } = useAccount();
  useEffect(() => {
    if (
      gameSeedSignerAddr &&
      address !== undefined &&
      address !== gameSeedSignerAddr
    ) {
      //登陆的钱包地址，不是之前签名的钱包地址，需要清空游戏数据
      setGameSeed(undefined);
      setGameSeedSignerAddr(undefined);
      setGameMoves([]);
      setPlayMintSignature('');
    }
  }, [
    address,
    gameSeedSignerAddr,
    setGameMoves,
    setGameSeed,
    setGameSeedSignerAddr,
    setPlayMintSignature
  ]);

  const renderContent = useCallback(() => {
    if (!isConnected) {
      return <ConnectWallet />;
    }
    if (
      mintType === EMintType.DIRECTLY ||
      (mintType === EMintType.PlAY && showPlayMintModal)
    ) {
      //直接mint不需要签名
      return <MintModal />;
    } else {
      //Plat to mint
      if (gameSeed !== undefined) {
        //已经签名并初始化游戏，应该展示游玩界面
        return <GamePlay />;
      } else {
        //需要弹出确认框，让用户点击play进行签名
        return <PlayConfirmModal />;
      }
    }
  }, [gameSeed, isConnected, mintType, showPlayMintModal]);

  return (
    <>
      <div
        className={cn(
          'relative flex min-h-[100vh] flex-col items-center justify-start pb-20 pt-[144px]'
        )}
      >
        <div className='absolute inset-0 -z-10 bg-[url("/bg/bg.png")] bg-cover bg-[center_top]'></div>
        <div className='absolute left-1/2 top-1/2 -z-10 hidden aspect-[1.2] w-[50%] -translate-x-1/2 -translate-y-[46%] bg-[url("/bg/word.png")] bg-contain bg-[center_top] bg-no-repeat sm:block'></div>
        <div className='absolute inset-0 -z-10 bg-[url("/bg/mock.png")] bg-cover bg-[center_top]'></div>
        {renderContent()}
      </div>
    </>
  );
};
export const Head = () => <Seo title="2048" />;
export default IndexPage;
