import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import LevelN from '@/assets/images/more/level_n.inline.svg';
import LevelR from '@/assets/images/more/level_r.inline.svg';
import LevelSR from '@/assets/images/more/level_sr.inline.svg';
import LevelSSR from '@/assets/images/more/level_ssr.inline.svg';

import { Split } from '../../common/Split';
const data = [
  {
    imgSvg: LevelN,
    amount: 1200,
    desc: 'A board containing a mix of elements from the 10 common themes: Wood, Jungle, Vintage, Solid Color, Snow, Sky, Business, Pool, Fruit, Candy.'
  },
  {
    imgSvg: LevelR,
    amount: 600,
    desc: 'A board containing a mix of elements from the 10 common themes with some elements from the Gold or Classic themes.'
  },
  {
    imgSvg: LevelSR,
    amount: 140,
    desc: (
      <span>
        A board containing a mix of elements from the{' '}
        <span className="font-medium text-[#FF71FD]">
          2 special themes: Gold and Classic.
        </span>
      </span>
    )
  },
  {
    imgSvg: LevelSSR,
    amount: 60,
    desc: (
      <span>
        A limited edition board representing one of the 12 themes.{' '}
        <span className="font-medium text-[#FF71FD]">
          Each theme is limited to 5 pieces.
        </span>
      </span>
    )
  }
];
export function SectionIntro() {
  return (
    <section>
      <div className="page-container mt-10 box-content !max-w-[913px]">
        <h2 className="text-xl md:text-[32px]">2048.ink Token Rarity</h2>
        <div className="my-[13px] flex flex-wrap items-center gap-x-[27px] gap-y-2">
          {data.map((item, index) => (
            <div key={index} className="flex items-center gap-x-1 text-base">
              <item.imgSvg width={38} />
              <span className="text-[#6C7B92]">Amount:</span>
              <span>{item.amount}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-y-2">
          <Split />
          {data.map((item, index) => (
            <div key={index} className="flex items-center gap-x-2">
              <item.imgSvg width={38} />
              <span className="flex-1 font-BaiJamjuree text-sm font-medium leading-[1.5] text-[#C1CFE5] md:text-base">
                {item.desc}
              </span>
            </div>
          ))}
        </div>
        <h2 className="mt-11 text-xl md:text-[32px]">
          2048.ink Token Examples
        </h2>
      </div>
      <div className="page-container box-content !max-w-[1000px]">
        <StaticImage
          src="../../../assets/images/more/chesses.png"
          objectFit="cover"
          alt="chesses"
        />
      </div>
    </section>
  );
}
