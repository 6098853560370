import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface LazyLoadProps {
  component: ReactNode;
}

export function LazyLoad(props: LazyLoadProps) {
  const { component } = props;

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(ref.current as unknown as Element);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <div ref={ref}>{isVisible ? component : <></>}</div>;
}
