import { InjectedConnector, InjectedConnectorOptions } from '@wagmi/core';

import { targetChain } from '@/components/common/WagmiProvider';

export class MIPDConnector extends InjectedConnector {
  readonly id: string;
  readonly rdns: string;

  constructor({
    options,
    uuid,
    rdns
  }: {
    options: InjectedConnectorOptions;
    uuid: string;
    rdns: string;
  }) {
    super({ options, chains: [targetChain] });

    this.id = uuid;
    this.rdns = rdns;
  }
}
