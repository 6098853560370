import en from './en';
import zh from './zh';

export const resources = {
  'en-US': {
    translation: en
  },
  zh: {
    translation: zh
  }
};
