import cn from 'classnames';
import React from 'react';

import diceIcon from '@/assets/images/diceIcon.svg';
import emailIcon from '@/assets/images/email.svg';
import footerBg from '@/assets/images/footerBg.png';
import telegramIcon from '@/assets/images/telegram.svg';

export function Footer() {
  return (
    <div
      className="w-full"
      style={{
        background: `url(${footerBg}) no-repeat`,
        backgroundSize: '100% 100%'
      }}
    >
      <footer
        className={cn(
          'page-container flex h-[168px] flex-col items-center justify-center gap-y-4 text-[#97A2B8] sm:flex-row sm:justify-between sm:gap-y-0'
        )}
      >
        <div className="flex items-center">
          <img className="h-[24px] md:h-[40px]" src={diceIcon} alt="icon" />
        </div>

        {/* <div className="inline-flex items-center gap-2">
          <a href="mailto:contact@playdice.ink">
            <img src={emailIcon} />
          </a>
          <a href="https://t.me/DICEInscription" target="_blank">
            <img src={telegramIcon} />
          </a>
        </div> */}
        <span className="text-base text-[#536581] sm:text-xl">
          Powered by{' '}
          <a
            href="https://playdice.ink/"
            target="_blank"
            className="text-[#6C7B92] underline duration-200 hover:opacity-75"
          >
            Dice Inscription
          </a>{' '}
          and{' '}
          <a
            href="https://evm.ink/"
            target="_blank"
            className="text-[#6C7B92] underline duration-200 hover:opacity-75"
          >
            evm.ink
          </a>
        </span>
      </footer>
    </div>
  );
}
