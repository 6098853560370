import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Loading } from './Loading';

interface IButtonProps {
  className?: string;
  onClick?: () => void;
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
}
export function Button(props: IButtonProps) {
  const { className, onClick, children, loading, disabled = false } = props;
  return (
    <button
      onClick={() => {
        if (loading || disabled) return;
        onClick && onClick();
      }}
      type="button"
      className={cn(
        className,
        disabled && 'cursor-not-allowed opacity-75',
        'flex items-center justify-center gap-x-[2px] rounded-[10px] bg-[linear-gradient(92deg,#FF30D5_-0.69%,#2E32FF_109.62%)] text-lg text-white  duration-200 hover:opacity-75 sm:text-2xl'
      )}
    >
      {children}
      {loading && <Loading className="h-[61.8%]" />}
    </button>
  );
}
