import type { PageProps } from 'gatsby';
import React from 'react';

import { SectionIntro, SectionMint, Seo } from '@/components';
const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <SectionMint />
      <SectionIntro />
    </>
  );
};
export const Head = () => <Seo title="Start Now" />;
export default IndexPage;
