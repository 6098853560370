import React, { useRef } from 'react';

import * as styles from '@/styles/text-3d.module.css';

export default function Index({
  primary,
  secondary
}: {
  primary: string;
  secondary: string;
}) {
  const text1 = useRef(null);
  const text2 = useRef(null);

  return (
    <div className={styles.textContainer}>
      <p className={styles.primary} ref={text1}>
        {primary}
      </p>
      <p className={styles.secondary} ref={text2}>
        {secondary}
      </p>
    </div>
  );
}
