import cn from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import diceIcon from '@/assets/images/diceIcon.svg';
import { ERouterPaths } from '@/utils/const';
import { usePageScrolled } from '@/utils/hooks';

import { AccountArea } from './AccountArea';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  const isScrolled = usePageScrolled();

  return (
    <header
      aria-label="Inscription Game Homepage Header"
      className={cn('fixed inset-x-0 top-7 z-50 h-[88px] px-4', className)}
    >
      <div
        className={cn(
          'page-container flex min-h-[60px] flex-col justify-center rounded-[14px] border-[1px] border-[#293341]  py-4 font-BakbakOne font-[400] md:py-0',
          isScrolled
            ? 'bg-[#1a132480] bg-opacity-0 backdrop-blur-[20px]'
            : 'bg-[#252E3C]'
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex cursor-pointer select-none items-center">
            <Link to="/">
              <img className="h-[28px] md:h-[36px]" src={diceIcon} alt="icon" />
            </Link>
          </div>
          <div className="flex flex-row items-center gap-x-4 sm:gap-x-10">
            <Link to={ERouterPaths.LEARN_MORE}>
              <span className="text-base sm:text-lg">PLAY</span>
            </Link>
            <AccountArea />
          </div>
        </div>
      </div>
    </header>
  );
}
