import { navigate } from 'gatsby';
import { nanoid } from 'nanoid';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { useAccount, useSignMessage } from 'wagmi';

import { Button } from '@/components';
import api from '@/services/api';
import { useAppStore } from '@/store/appStore';
import { ERouterPaths } from '@/utils/const';

import { Modal } from './Modal';

export function PlayConfirmModal() {
  const { setGameSeed, setGameSeedSignerAddr } = useAppStore();
  const { signMessageAsync } = useSignMessage();
  const { address } = useAccount();
  const [signResult, doSignInMessage] = useAsyncFn(async () => {
    if (!address) return;
    const signMessage = generateSignMessage(address);
    const signature = await signMessageAsync({ message: signMessage });
    const seed = await api.verifierController.signForSeedUsingPost({
      nonce: signMessage,
      signature,
      address
    });
    setGameSeed(Number(seed));
    setGameSeedSignerAddr(address);
  }, [address, signMessageAsync]);

  return (
    <Modal
      onClose={() => {
        navigate(ERouterPaths.LEARN_MORE);
      }}
      className="!absolute top-1/2 -translate-y-1/2"
      title={
        <span className="text-xl leading-normal sm:text-[32px]">
          Welcome to{' '}
          <span className="bg-[linear-gradient(91deg,#FF54E1_42.74%,#8E59FF_59.6%,#5646FF_76.29%)] bg-clip-text text-transparent">
            Play to Mint
          </span>{' '}
          Zone!
        </span>
      }
    >
      <p className="mb-4 mt-7 text-center font-BaiJamjuree text-sm font-medium leading-[1.56] text-[#C1CFE5] sm:text-lg">
        Play a game of 2048, the higher your score, the higher your probability
        of minting a rare token.
      </p>
      <p className="mb-7 text-center font-BaiJamjuree text-sm font-medium leading-[1.56] text-[#C1CFE5] sm:text-lg">
        If you aren't happy with your score, you can restart or try again as
        many times as you'd like! Don't take too long though, there is a limited
        supply of rare tokens and they will only be mintable while supplies
        last, regardless of your score!
      </p>
      <Button
        onClick={doSignInMessage}
        loading={signResult.loading}
        className="h-[42px] w-[231px] sm:!text-xl"
      >
        Play
      </Button>
    </Modal>
  );
}

function generateSignMessage(address: string) {
  return `This request will not trigger a blockchain transaction or cost any gas fees. Wallet address: ${address} Nonce: ${nanoid()}`;
}
