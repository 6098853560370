import { useMemo } from 'react';

import { useSize } from './useSize';

export function useHeaderHeight() {
  const { isBiggerThanLG } = useSize();
  const headerHeightCls = useMemo(() => {
    return isBiggerThanLG ? 'h-[88px]' : 'h-16';
  }, [isBiggerThanLG]);
  const headerHeightValue = useMemo(
    () => (isBiggerThanLG ? 88 : 64),
    [isBiggerThanLG]
  );
  return {
    headerHeightCls,
    headerHeightValue
  };
}
