import cn from 'classnames';
import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useLocation } from 'react-use';
import { useAccount, useDisconnect } from 'wagmi';

import CopySVG from '@/assets/images/2048/copy.inline.svg';
import defaultAvatar from '@/assets/images/2048/default_avatar.png';
import DisconnectSVG from '@/assets/images/2048/disconnect.inline.svg';
import { useAppStore } from '@/store/appStore';
import { ellipseAddress } from '@/utils/common';
import { ERouterPaths } from '@/utils/const';

import { Button } from '../../Button';
interface IAccountAreaProps {
  className?: string;
}
export function AccountArea(props: IAccountAreaProps) {
  const { className } = props;
  const { address, isConnected } = useAccount();
  const { pathname } = useLocation();
  const {
    setPlayMintSignature,
    playMintSignature,
    setGameMoves,
    setShowLoginModal
  } = useAppStore();
  const { disconnect } = useDisconnect({
    onSuccess: () => {
      if (playMintSignature) {
        setGameMoves([]);
      }
      setPlayMintSignature(undefined);
    }
  });

  useEffect(() => {
    if (isConnected) {
      setShowLoginModal(false);
    }
  }, [isConnected, setShowLoginModal]);
  if (!isConnected) {
    return (
      <>
        <Button
          onClick={() => {
            if (!pathname?.includes(ERouterPaths.GAME_2048)) {
              setShowLoginModal(true);
            }
          }}
          className="h-8 w-20 rounded-md text-base sm:h-10 sm:w-40 sm:!text-lg"
        >
          Connect
        </Button>
      </>
    );
  }
  return (
    <div
      className={cn(
        className,
        'group relative flex h-9 cursor-pointer items-center gap-x-2'
      )}
    >
      <img
        src={defaultAvatar}
        className="w-6 rounded-sm sm:w-9 sm:rounded-lg"
        alt="avatar"
      />
      <span className="hidden text-base uppercase sm:block sm:text-lg">
        {ellipseAddress(address)}
      </span>
      <div className="absolute -left-28 -right-1 top-12 h-0 overflow-hidden rounded-lg bg-[#4f5b6d] text-sm transition-[height] duration-200 group-hover:h-[132px] sm:left-0 sm:text-base">
        <div
          onClick={() => {
            window.open(
              `https://evm.ink/address/${address}?currentTab=inscriptions`,
              '__blank'
            );
          }}
          className="border-box flex h-11 cursor-pointer items-center gap-x-2 border-b-[1px] border-solid border-b-[#37404c] px-3 font-Poppins font-medium text-[#AAB9CE] duration-200 hover:text-[#fff] sm:px-6"
        >
          <span className="whitespace-nowrap">View on evm.ink</span>
        </div>
        <CopyToClipboard
          onCopy={() => {
            toast.success('Copied!');
          }}
          text={address || ''}
        >
          <div className="border-box flex h-11 cursor-pointer items-center gap-x-2 border-b-[1px] border-solid border-b-[#37404c] px-3 font-Poppins font-medium text-[#AAB9CE] duration-200 hover:text-[#fff] sm:px-6">
            <CopySVG />
            <span className="ml-1">Copy</span>
          </div>
        </CopyToClipboard>

        <div
          onClick={() => {
            disconnect();
          }}
          className="border-box flex h-11 cursor-pointer items-center gap-x-2 border-b-[1px] border-solid border-b-[#37404c] px-3 font-Poppins font-medium text-[#AAB9CE] duration-200 hover:text-[#fff] sm:px-6"
        >
          <DisconnectSVG className="shrink-0" />
          <span>Disconnect</span>
        </div>
      </div>
    </div>
  );
}
