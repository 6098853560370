import { useEffect } from 'react';

import { useAppStore } from '@/store/appStore';

let isFetched = false;
export function useGameInit() {
  const { setInitialized } = useAppStore();
  useEffect(() => {
    if (isFetched) return;
    isFetched = true;
    async function initWasm() {
      try {
        const res = await fetch(
          'https://inscription-2048-frontend.s3.ap-northeast-1.amazonaws.com/assets/twothousand_forty_eight_bg.wasm'
        )
          .then((res) => res.arrayBuffer())
          .then((ab) =>
            WebAssembly.instantiate(ab, {
              './twothousand_forty_eight_bg.js': window.game
            })
          );
        window.game.__wbg_set_wasm(res.instance.exports);
        setInitialized(true);
      } catch (e) {
        console.error(e);
      }
    }
    initWasm();
  }, [setInitialized]);
}
