import cn from 'classnames';
import React, { useMemo } from 'react';
import { useSearchParam } from 'react-use';

import PlusSVG from '@/assets/images/2048/plus.inline.svg';
import { EMintType, MintTypePSearchParamKey } from '@/pages/games/2048';
interface IAmountInputProps {
  className?: string;
  mintAmount: number;
  setMintAmount: (amount: number) => void;
}
const DirectMintAmountLimit = 5;
export function AmountInput(props: IAmountInputProps) {
  const { className, mintAmount, setMintAmount } = props;
  const mintType = useSearchParam(MintTypePSearchParamKey);

  const plusEnable = useMemo(() => {
    if (mintType === EMintType.PlAY || mintAmount >= DirectMintAmountLimit) {
      return false;
    }
    return true;
  }, [mintAmount, mintType]);

  const minusEnable = useMemo(() => {
    if (mintType === EMintType.PlAY || mintAmount === 1) {
      return false;
    }
    return true;
  }, [mintAmount, mintType]);
  return (
    <div className={cn(className, 'box-border flex items-center')}>
      {/* minus */}
      <button
        onClick={() => {
          if (minusEnable && mintAmount > 1) {
            setMintAmount(mintAmount - 1);
          }
        }}
        className={cn(
          'flex h-9 w-9 items-center justify-center rounded-l-[6px] border-[1px] border-solid border-[#485568] bg-[#313A48]',
          !minusEnable && 'cursor-not-allowed'
        )}
      >
        <div
          className="h-[2px] w-4"
          style={{
            backgroundColor: minusEnable ? '#C1CFE5' : '#536581'
          }}
        ></div>
      </button>
      <div className="flex h-9 w-[48px] items-center justify-center border-y-[1px] border-solid border-[#485568] bg-[#313A48] text-xl text-[#C1CFE5]">
        {mintAmount}
      </div>
      <button
        onClick={() => {
          if (plusEnable && mintAmount <= 4) {
            setMintAmount(mintAmount + 1);
          }
        }}
        className={cn(
          'flex h-9 w-9 items-center justify-center rounded-r-[6px] border-[1px] border-solid border-[#485568] bg-[#313A48]',
          !plusEnable && 'cursor-not-allowed'
        )}
      >
        <PlusSVG
          style={{
            color: plusEnable ? '#C1CFE5' : '#536581'
          }}
        />
      </button>
    </div>
  );
}
