export * from './common/Button';
export * from './common/Layout';
export * from './common/Layout/Footer';
export * from './common/LazyLoad';
export * from './common/Loading';
export * from './common/RootElement';
export * from './common/Seo';
//home page
export * from './pages/home/SectionFirstScreen';
export * from './pages/home/SectionInscription';
//mint page
export * from './pages/more/SectionIntro';
export * from './pages/more/SectionMint';
//2048 page
export * from './pages/2048/ConnectWallet';
export * from './pages/2048/GamePlay';
export * from './pages/2048/MintModal';
export * from './pages/2048/Modal';
export * from './pages/2048/PlayConfirmModal';
