import cn from 'classnames';
import React, { ReactNode } from 'react';
interface IModalLineProps {
  className?: string;
  children: ReactNode;
  label: string;
}
export function ModalLine(props: IModalLineProps) {
  const { className, children, label } = props;
  return (
    <div
      className={cn(
        className,
        'flex items-center gap-x-[6px] text-lg text-[#C1CFE5] sm:text-xl'
      )}
    >
      <span className="text-[#6C7B92]">{label}:</span>
      {children}
    </div>
  );
}
