import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import BoardPreviewActive from '@/assets/images/2048/board_preview_active.inline.svg';
import BoardPreviewInActive from '@/assets/images/2048/board_preview_inactive.inline.svg';

import { PreviewBoard } from './PreivewBoard';
interface IMintSuccessProps {
  className?: string;
  mintedTokenIds: number[];
  txHash: string;
}
export function MintSuccess(props: IMintSuccessProps) {
  const { className, mintedTokenIds, txHash } = props;
  const [activeTokenId, setActiveTokenId] = useState<number>();
  useEffect(() => {
    if (mintedTokenIds[0] !== undefined) {
      setActiveTokenId(mintedTokenIds[0]);
    }
  }, [mintedTokenIds]);

  if (activeTokenId === undefined) return;
  return (
    <div className={cn(className)}>
      {mintedTokenIds.length > 1 && (
        <div
          className={cn(
            mintedTokenIds.length
              ? 'mx-auto -mt-4 mb-4 flex items-center justify-center gap-x-3'
              : ''
          )}
        >
          {mintedTokenIds.map((tokenId, index) => (
            <div
              onClick={() => {
                setActiveTokenId(tokenId);
              }}
              key={index}
              className="cursor-pointer"
            >
              {tokenId === activeTokenId ? (
                <BoardPreviewActive
                  className={cn('-mx-1 w-[60px] sm:w-[65px]')}
                />
              ) : (
                <BoardPreviewInActive className="w-[56px] sm:w-[60px]" />
              )}
            </div>
          ))}
        </div>
      )}
      <PreviewBoard
        txHash={txHash}
        tokenIds={mintedTokenIds}
        tokenId={activeTokenId}
      />
    </div>
  );
}
