import { navigate } from 'gatsby';
import React, { useCallback, useMemo, useState } from 'react';
import { useConnect, useDisconnect } from 'wagmi';

import MetaMaskSVG from '@/assets/images/2048/metamask.inline.svg';
import TrustSVG from '@/assets/images/2048/trust.inline.svg';
import { Loading } from '@/components/common/Loading';
import { targetChain } from '@/components/common/WagmiProvider';
import { ERouterPaths, EWalletTypes } from '@/utils/const';

import { Modal } from './Modal';
interface IConnectWalletProps {
  onClose?: () => void;
}
export function ConnectWallet({ onClose }: IConnectWalletProps) {
  // 设置chainid以后，connect的时候会自动add and switch to chain
  const { connectors, isLoading, pendingConnector, connect } = useConnect({
    chainId: targetChain.id,
    onError: (e) => {
      console.error(e);
      disconnect();
    }
  });
  const { disconnect } = useDisconnect();
  const onConnectWallet = useCallback(
    async (type: EWalletTypes) => {
      if (isLoading) {
        return;
      }
      const connectorId =
        type === EWalletTypes.METAMASK ? 'io.metamask' : 'com.trustwallet.app';
      const walletConnector = connectors.find(
        (item) => item.id === connectorId
      );
      if (!walletConnector) {
        return;
      }
      connect({
        connector: walletConnector
      });
    },
    [connect, connectors, isLoading]
  );
  const wallets = useMemo(
    () => [
      {
        icon: MetaMaskSVG,
        title: 'Metamask',
        loading: isLoading && pendingConnector?.id === 'io.metamask',
        onClick: () => {
          onConnectWallet(EWalletTypes.METAMASK);
        }
      },

      {
        icon: TrustSVG,
        title: 'Trust',
        loading: isLoading && pendingConnector?.id === 'com.trustwallet.app',
        onClick: () => {
          onConnectWallet(EWalletTypes.TRUST);
        }
      }
    ],
    [isLoading, onConnectWallet, pendingConnector?.id]
  );
  return (
    <Modal
      title="Connect Wallet"
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          navigate(ERouterPaths.LEARN_MORE);
        }
      }}
      className="!absolute top-1/2 -translate-y-1/2"
    >
      <div className="-mt-4 flex w-full flex-col gap-y-3 sm:mb-6 sm:mt-0 sm:gap-y-6">
        {wallets.map((wallet) => (
          <div
            key={wallet.title}
            onClick={wallet.onClick}
            className="relative flex h-10 w-full cursor-pointer items-center gap-x-2 rounded-md border-[1px] border-solid border-transparent bg-[#313A48] px-6 text-base font-medium text-[#E5ECF4] duration-200 hover:opacity-75 sm:h-14 sm:rounded-[10px] sm:text-lg"
          >
            <wallet.icon className="origin-center scale-[0.75] sm:scale-100" />
            {wallet.title}
            {wallet.loading && (
              <Loading className="absolute right-4 top-1/2 -translate-y-1/2" />
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
}
