import cn from 'classnames';
import React from 'react';

import LoadingSVG from '@/assets/images/common/loading.inline.svg';
interface ILoadingProps {
  className?: string;
}
export function Loading(props: ILoadingProps) {
  const { className } = props;
  return <LoadingSVG className={cn(className)} />;
}
