import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Direction } from '@/types/global';

interface AppState {
  gameSeed?: number;
  setGameSeed: (seed: number | undefined) => void;
  gameMoves: Direction[];
  //签发获取gameSeed的钱包地址
  gameSeedSignerAddr?: string;
  setGameSeedSignerAddr: (addr?: string) => void;
  setGameMoves: (moves: Direction[]) => void;
  isInitialized: boolean;
  setInitialized: (initialized: boolean) => void;
  score?: number;
  setScore: (score: number | undefined) => void;
  playMintSignature?: string;
  setPlayMintSignature: (signature: string | undefined) => void;
  showLoginModal?: boolean;
  setShowLoginModal: (show?: boolean) => void;
}

export const useAppStore = create<AppState>()(
  persist(
    devtools(
      immer((set) => ({
        gameSeed: undefined,
        setGameSeed: (seed: number | undefined) =>
          set(
            (state) => {
              state.gameSeed = seed;
            },
            false,
            'setGameSeed'
          ),
        score: undefined,
        setScore: (score: number | undefined) =>
          set(
            (state) => {
              state.score = score;
            },
            false,
            'setScore'
          ),
        gameMoves: [],
        setGameMoves: (moves: Direction[]) =>
          set(
            (state) => {
              state.gameMoves = moves;
            },
            false,
            'setGameMoves'
          ),
        isInitialized: false,
        setInitialized: (initialized: boolean) =>
          set(
            (state) => {
              state.isInitialized = initialized;
            },
            false,
            'setInitialized'
          ),
        showLoginModal: false,
        setShowLoginModal: (show?: boolean) =>
          set(
            (state) => {
              state.showLoginModal = show;
            },
            false,
            'setShowLoginModal'
          ),
        setPlayMintSignature: (signature?: string) =>
          set(
            (state) => {
              state.playMintSignature = signature;
            },
            false,
            'setPlayMintSignature'
          ),
        setGameSeedSignerAddr: (addr?: string) =>
          set(
            (state) => {
              state.gameSeedSignerAddr = addr;
            },
            false,
            'setGameSeedSignerAddr'
          )
      }))
    ),
    {
      // state存储到local storage中的key名
      name: 'app-storage', // 保存项
      partialize: (state) => ({
        gameSeed: state.gameSeed,
        gameMoves: state.gameMoves,
        gameSeedSignerAddr: state.gameSeedSignerAddr
      })
    }
  )
);
