export default {
  'home.first.projects': 'Project Collaborations',
  'home.first.contracts': 'Smart Contracts Compiled',
  'home.first.developers': 'Developers',
  'home.first.increase': 'Integrated Blockchains',

  'home.intro.title': 'Thrive with ChainIDE',
  'home.intro.learn.title': 'Learn',
  'home.intro.learn.desc': `ChainIDE offers Web3 courses for all skill levels. Whether you're a beginner or an experienced developer, you'll find the perfect course to meet your needs.`,
  'home.intro.build.title': 'Build',
  'home.intro.build.desc':
    'ChainIDE, powered by OpenAI, revolutionizes multi-chain smart contract and DApp development. Start coding with ease! ',
  'home.intro.join.title': 'Join Us',
  'home.intro.join.desc':
    'ChainIDE is dedicated to building and expanding the global developer ecosystem. Join our community to connect with developers from around the world.',

  'home.edu.title': 'ChainIDE Education',
  'home.edu.desc': 'Learning Tool',
  'home.edu.conversation.title': 'Conversational Teaching',
  'home.edu.conversation.desc':
    'We present all of our tutorials in a creative dialogue format, making learning as easy as chatting.',
  'home.edu.split.title': 'Split-Screen Learning',
  'home.edu.split.desc':
    'ChainIDE revolutionizes learning with its all-in-one view.',
  'home.edu.video.title': 'Video-Based Lessons',
  'home.edu.video.desc':
    'ChainIDE offers interactive video courses with coding guidance.',
  'home.edu.together.title': 'Learn Together! ',
  'home.edu.together.desc':
    'View the number of fellow students studying with you and their avatars.',
  'home.edu.button.text': 'Start Learning',

  'home.studio.title': 'ChainIDE Studio',
  'home.studio.desc': 'Coding Tools',
  'home.studio.ai.title': 'Code with the Power of AI',
  'home.studio.ai.desc':
    'ChainIDE Code Sage, an intelligent coding AI, enhances coding efficiency and user experience by answering code-related questions.',
  'home.studio.code.title': 'Full-Stack Dapp Development Platform',
  'home.studio.code.desc':
    'ChainIDE offers full-stack developers the ability to seamlessly code, debug, test, and deploy their Dapps, all online and accessible with just one click, without the need for additional tools. ',
  'home.studio.multi.title': 'A Cloud-Based Multi-Chain IDE',
  'home.studio.multi.desc': `ChainIDE empowers seamless deployment across various chains, including Ethereum and BNB Chain, harnessing the cloud's capabilities. Benefit from cloud-based compilation and rapid startup, all with no setup required.`,
  'home.studio.plugin.title': 'Versatile VSCode Compatible Plugins',
  'home.studio.plugin.desc': `ChainIDE provides an extensive range of developer plugins, covering topics like formatting, code detection, security, and more. It's also compatible with VSCode's plugin system.`,
  'home.studio.button.text': 'Start Coding',

  'home.events.title': 'ChainIDE Events',
  'home.events.desc': 'Join Us',
  'home.events.bootcamp.title': 'Bootcamps & Hackathons',
  'home.events.bootcamp.desc':
    'Our Bootcamps & Hackathons are committed to building a technology exchange platform for global blockchain developers to help developers achieve breakthroughs and reach an advanced level.',
  'home.events.workshop.title': 'Workshops & Seminars',
  'home.events.workshop.desc':
    'Our workshops and seminars aim to popularize blockchain knowledge, discuss development trends with industry insiders, and promote innovation in the blockchain ecosystem.',
  'home.events.summit.title': 'Developer Summits',
  'home.events.summit.desc':
    'Developer Summits and Conferences are platforms for our team to engage in profound communication and knowledge exchange with the global tech community.',
  'home.events.button.text': 'Join Now',

  'home.future.title': 'Future Plans',
  'home.future.desc': 'Our Vision',
  'home.future.membership.title': 'Premium Memberships',
  'home.future.membership.desc':
    'Memberships will offer premium features, discounted fees, and priority support for a superior user experience.',
  'home.future.marketplace.title': 'ChainIDE Marketplace',
  'home.future.marketplace.desc':
    'ChainIDE Marketplace will enhance your developer experience with its diverse plugins and templates.',
  'home.future.dao.title': 'ChainIDE DAO',
  'home.future.dao.desc':
    'ChainIDE DAO will be a decentralized autonomous organization responsible for overseeing the development and operation of ChainIDE.',
  'home.future.bounty.title': 'ChainIDE Bounty Pool',
  'home.future.bounty.desc':
    'ChainIDE Bounty Pool will reward developers for identifying and reporting platform issues, bugs, and vulnerabilities.',

  'home.stories.title': 'Success Stories',
  'home.stories.desc': 'Our Achievements',
  'home.stories.mw.title': 'Matrix World',
  'home.stories.mw.desc':
    'Matrix World is an exciting next-generation metaverse where users can create, connect, explore, and play like never before. The open world enables users to build immersive 3D applications on top of several different blockchains. ',
  'home.stories.mm.title': 'Matrix Market',
  'home.stories.mm.desc':
    'Matrix Market is the first all-in-one marketplace for creators, collectors, and traders on Flow.',
  'home.stories.pb.title': 'PhantaBear',
  'home.stories.pb.desc': `PhantaBear, a collaboration by Jay Chou's PHANTACi and Ezek, is a collection of 10,000 algorithmically generated digital collectibles. It doubles as membership cards for the Ezek Club, offering unique traits and various access levels and perks to owners.`,
  'home.stories.rm.title': 'Rivermen',
  'home.stories.rm.desc':
    'Rivermen is an Oriental Cyber Theme Park based on blockchain technology, launched by the Cthuwork&NA team using elements from the voxel version of the “Along the River During Qingming Festival”. Each unique Rivermen NFT is a randomized 3D character model.',
  'home.stories.ts.title': 'Theirsverse',
  'home.stories.ts.desc':
    'Theirsverse has created a brand connecting Web 2 and Web 3. From NFT artworks to fashion toys and DTC beauty brands, Theirsverse would like to invite creators globally to co-create our brand together.',
  'home.stories.gld.title': 'GrandLineDAO',
  'home.stories.gld.desc': `Wake up!
Push the boundaries!
Overcome hardships!
Dream together!
Let us ride the sea of polarization！
  `,

  'home.partners.title': 'Partners'
};
