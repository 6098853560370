import { BASE_URL } from 'gatsby-env-variables';
import { extend } from 'umi-request';
/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
const request = extend({
  prefix: BASE_URL,
  timeout: 30 * 1000 // 30s
});

// // 响应拦截器
// request.interceptors.response.use((response) => {
//   if (
//     response.status === 200 &&
//     response.headers.get('Content-Type') === 'application/json'
//   ) {
//     return response.json().then((data) => {
//       // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//       return data;
//     });
//   }
//   return response;
// });

export default request;
