import cn from 'classnames';
import { ReactNode, useCallback } from 'react';
import React from 'react';

import CloseSVG from '@/assets/images/2048/close.inline.svg';
interface IModalProps {
  className?: string;
  title?: ReactNode;
  children: ReactNode;
  onClose: () => void;
}
export function Modal(props: IModalProps) {
  const { className, title = '', children, onClose } = props;
  const renderTitle = useCallback(() => {
    if (typeof title === 'string') {
      return (
        <h2 className="mb-8 text-2xl leading-normal sm:text-[32px]">{title}</h2>
      );
    } else {
      return title;
    }
  }, [title]);
  return (
    <div
      className={cn(
        className,
        'relative flex w-[calc(100vw_-_32px)] max-w-[607px] flex-col items-center justify-center rounded-lg bg-[#252E3C] px-4 py-8 shadow-md sm:rounded-[14px] sm:px-[56px]'
      )}
    >
      {renderTitle()}
      <CloseSVG
        onClick={onClose}
        className="absolute right-3 top-2 w-4 cursor-pointer duration-200 hover:opacity-75 sm:right-[26px] sm:top-[21px] sm:w-[22px]"
      />
      {children}
    </div>
  );
}
