import cn from 'classnames';
import React from 'react';

import ScoreSVG from '@/assets/images/2048/score_bg.inline.svg';
interface IScoreProps {
  className?: string;
  value?: number;
}
export function Score(props: IScoreProps) {
  const { className, value } = props;
  return (
    <div
      className={cn(
        className,
        'relative flex h-[68px] w-[calc(100vw_-_32px)] max-w-[533px] items-center justify-center rounded-[20px] border-[1px] border-solid border-[#3F4E65] bg-[#252E3C]'
      )}
    >
      <ScoreSVG className="absolute left-0 top-[1px] h-[68px]" />
      <span className="ml-20 text-[40px] sm:ml-0">{value}</span>
    </div>
  );
}
