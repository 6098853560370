import '@/styles/global.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'animate.css';

import React from 'react';
import store from 'store2';

import { Layout, RootElement } from '@/components';

export const onRouteUpdate = ({ prevLocation }: { prevLocation: Location }) => {
  if (prevLocation?.pathname) {
    store.set('prePathname', prevLocation?.pathname);
  }
};

export const wrapRootElement = ({ element }: { element: JSX.Element }) => {
  return <RootElement>{element}</RootElement>;
};

// Wraps every page in a component
export const wrapPageElement = ({
  element,
  props
}: {
  element: JSX.Element;
  props: Record<string, unknown>;
}) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  const script = document.createElement('script');
  script.src =
    'https://inscription-2048-frontend.s3.ap-northeast-1.amazonaws.com/assets/wasm_init.js';
  script.async = false;
  document.head.appendChild(script);
};
