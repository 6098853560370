export const allGames = [
  'AAAB',
  'AAAC',
  'AAAD',
  'AAAE',
  'AABA',
  'AABB',
  'AABC',
  'AABD',
  'AABE',
  'AACA',
  'AACB',
  'AACC',
  'AACD',
  'AACE',
  'AADA',
  'AADB',
  'AADC',
  'AADD',
  'AADE',
  'AAEA',
  'AAEB',
  'AAEC',
  'AAED',
  'AAEE',
  'ABAA',
  'ABAB',
  'ABAC',
  'ABAD',
  'ABAE',
  'ABBA',
  'ABBB',
  'ABBC',
  'ABBD',
  'ABBE',
  'ABCA',
  'ABCB',
  'ABCC',
  'ABCD',
  'ABCE',
  'ABDA',
  'ABDB',
  'ABDC',
  'ABDD',
  'ABDE',
  'ABEA',
  'ABEB',
  'ABEC',
  'ABED',
  'ABEE',
  'ACAA',
  'ACAB',
  'ACAC',
  'ACAD',
  'ACAE',
  'ACBA',
  'ACBB',
  'ACBC',
  'ACBD',
  'ACBE',
  'ACCA',
  'ACCB',
  'ACCC',
  'ACCD',
  'ACCE',
  'ACDA',
  'ACDB',
  'ACDC',
  'ACDD',
  'ACDE',
  'ACEA',
  'ACEB',
  'ACEC',
  'ACED',
  'ACEE',
  'ADAA',
  'ADAB',
  'ADAC',
  'ADAD',
  'ADAE',
  'ADBA',
  'ADBB',
  'ADBC',
  'ADBD',
  'ADBE',
  'ADCA',
  'ADCB',
  'ADCC',
  'ADCD',
  'ADCE',
  'ADDA',
  'ADDB',
  'ADDC',
  'ADDD',
  'ADDE',
  'ADEA',
  'ADEB',
  'ADEC',
  'ADED',
  'ADEE',
  'AEAA',
  'AEAB',
  'AEAC',
  'AEAD',
  'AEAE',
  'AEBA',
  'AEBB',
  'AEBC',
  'AEBD',
  'AEBE',
  'AECA',
  'AECB',
  'AECC',
  'AECD',
  'AECE',
  'AEDA',
  'AEDB',
  'AEDC',
  'AEDD',
  'AEDE',
  'AEEA',
  'AEEB',
  'AEEC',
  'AEED',
  'AEEE',
  'BAAA',
  'BAAB',
  'BAAC',
  'BAAD',
  'BAAE',
  'BABA',
  'BABB',
  'BABC',
  'BABD',
  'BABE',
  'BACA',
  'BACB',
  'BACC',
  'BACD',
  'BACE',
  'BADA',
  'BADB',
  'BADC',
  'BADD',
  'BADE',
  'BAEA',
  'BAEB',
  'BAEC',
  'BAED',
  'BAEE',
  'BBAA',
  'BBAB',
  'BBAC',
  'BBAD',
  'BBAE',
  'BBBA',
  'BBBC',
  'BBBD',
  'BBBE',
  'BBCA',
  'BBCB',
  'BBCC',
  'BBCD',
  'BBCE',
  'BBDA',
  'BBDB',
  'BBDC',
  'BBDD',
  'BBDE',
  'BBEA',
  'BBEB',
  'BBEC',
  'BBED',
  'BBEE',
  'BCAA',
  'BCAB',
  'BCAC',
  'BCAD',
  'BCAE',
  'BCBA',
  'BCBB',
  'BCBC',
  'BCBD',
  'BCBE',
  'BCCA',
  'BCCB',
  'BCCC',
  'BCCD',
  'BCCE',
  'BCDA',
  'BCDB',
  'BCDC',
  'BCDD',
  'BCDE',
  'BCEA',
  'BCEB',
  'BCEC',
  'BCED',
  'BCEE',
  'BDAA',
  'BDAB',
  'BDAC',
  'BDAD',
  'BDAE',
  'BDBA',
  'BDBB',
  'BDBC',
  'BDBD',
  'BDBE',
  'BDCA',
  'BDCB',
  'BDCC',
  'BDCD',
  'BDCE',
  'BDDA',
  'BDDB',
  'BDDC',
  'BDDD',
  'BDDE',
  'BDEA',
  'BDEB',
  'BDEC',
  'BDED',
  'BDEE',
  'BEAA',
  'BEAB',
  'BEAC',
  'BEAD',
  'BEAE',
  'BEBA',
  'BEBB',
  'BEBC',
  'BEBD',
  'BEBE',
  'BECA',
  'BECB',
  'BECC',
  'BECD',
  'BECE',
  'BEDA',
  'BEDB',
  'BEDC',
  'BEDD',
  'BEDE',
  'BEEA',
  'BEEB',
  'BEEC',
  'BEED',
  'BEEE',
  'CAAA',
  'CAAB',
  'CAAC',
  'CAAD',
  'CAAE',
  'CABA',
  'CABB',
  'CABC',
  'CABD',
  'CABE',
  'CACA',
  'CACB',
  'CACC',
  'CACD',
  'CACE',
  'CADA',
  'CADB',
  'CADC',
  'CADD',
  'CADE',
  'CAEA',
  'CAEB',
  'CAEC',
  'CAED',
  'CAEE',
  'CBAA',
  'CBAB',
  'CBAC',
  'CBAD',
  'CBAE',
  'CBBA',
  'CBBB',
  'CBBC',
  'CBBD',
  'CBBE',
  'CBCA',
  'CBCB',
  'CBCC',
  'CBCD',
  'CBCE',
  'CBDA',
  'CBDB',
  'CBDC',
  'CBDD',
  'CBDE',
  'CBEA',
  'CBEB',
  'CBEC',
  'CBED',
  'CBEE',
  'CCAA',
  'CCAB',
  'CCAC',
  'CCAD',
  'CCAE',
  'CCBA',
  'CCBB',
  'CCBC',
  'CCBD',
  'CCBE',
  'CCCA',
  'CCCB',
  'CCCD',
  'CCCE',
  'CCDA',
  'CCDB',
  'CCDC',
  'CCDD',
  'CCDE',
  'CCEA',
  'CCEB',
  'CCEC',
  'CCED',
  'CCEE',
  'CDAA',
  'CDAB',
  'CDAC',
  'CDAD',
  'CDAE',
  'CDBA',
  'CDBB',
  'CDBC',
  'CDBD',
  'CDBE',
  'CDCA',
  'CDCB',
  'CDCC',
  'CDCD',
  'CDCE',
  'CDDA',
  'CDDB',
  'CDDC',
  'CDDD',
  'CDDE',
  'CDEA',
  'CDEB',
  'CDEC',
  'CDED',
  'CDEE',
  'CEAA',
  'CEAB',
  'CEAC',
  'CEAD',
  'CEAE',
  'CEBA',
  'CEBB',
  'CEBC',
  'CEBD',
  'CEBE',
  'CECA',
  'CECB',
  'CECC',
  'CECD',
  'CECE',
  'CEDA',
  'CEDB',
  'CEDC',
  'CEDD',
  'CEDE',
  'CEEA',
  'CEEB',
  'CEEC',
  'CEED',
  'CEEE',
  'DAAA',
  'DAAB',
  'DAAC',
  'DAAD',
  'DAAE',
  'DABA',
  'DABB',
  'DABC',
  'DABD',
  'DABE',
  'DACA',
  'DACB',
  'DACC',
  'DACD',
  'DACE',
  'DADA',
  'DADB',
  'DADC',
  'DADD',
  'DADE',
  'DAEA',
  'DAEB',
  'DAEC',
  'DAED',
  'DAEE',
  'DBAA',
  'DBAB',
  'DBAC',
  'DBAD',
  'DBAE',
  'DBBA',
  'DBBB',
  'DBBC',
  'DBBD',
  'DBBE',
  'DBCA',
  'DBCB',
  'DBCC',
  'DBCD',
  'DBCE',
  'DBDA',
  'DBDB',
  'DBDC',
  'DBDD',
  'DBDE',
  'DBEA',
  'DBEB',
  'DBEC',
  'DBED',
  'DBEE',
  'DCAA',
  'DCAB',
  'DCAC',
  'DCAD',
  'DCAE',
  'DCBA',
  'DCBB',
  'DCBC',
  'DCBD',
  'DCBE',
  'DCCA',
  'DCCB',
  'DCCC',
  'DCCD',
  'DCCE',
  'DCDA',
  'DCDB',
  'DCDC',
  'DCDD',
  'DCDE',
  'DCEA',
  'DCEB',
  'DCEC',
  'DCED',
  'DCEE',
  'DDAA',
  'DDAB',
  'DDAC',
  'DDAD',
  'DDAE',
  'DDBA',
  'DDBB',
  'DDBC',
  'DDBD',
  'DDBE',
  'DDCA',
  'DDCB',
  'DDCC',
  'DDCD',
  'DDCE',
  'DDDA',
  'DDDB',
  'DDDC',
  'DDDE',
  'DDEA',
  'DDEB',
  'DDEC',
  'DDED',
  'DDEE',
  'DEAA',
  'DEAB',
  'DEAC',
  'DEAD',
  'DEAE',
  'DEBA',
  'DEBB',
  'DEBC',
  'DEBD',
  'DEBE',
  'DECA',
  'DECB',
  'DECC',
  'DECD',
  'DECE',
  'DEDA',
  'DEDB',
  'DEDC',
  'DEDD',
  'DEDE',
  'DEEA',
  'DEEB',
  'DEEC',
  'DEED',
  'DEEE',
  'EAAA',
  'EAAB',
  'EAAC',
  'EAAD',
  'EAAE',
  'EABA',
  'EABB',
  'EABC',
  'EABD',
  'EABE',
  'EACA',
  'EACB',
  'EACC',
  'EACD',
  'EACE',
  'EADA',
  'EADB',
  'EADC',
  'EADD',
  'EADE',
  'EAEA',
  'EAEB',
  'EAEC',
  'EAED',
  'EAEE',
  'EBAA',
  'EBAB',
  'EBAC',
  'EBAD',
  'EBAE',
  'EBBA',
  'EBBB',
  'EBBC',
  'EBBD',
  'EBBE',
  'EBCA',
  'EBCB',
  'EBCC',
  'EBCD',
  'EBCE',
  'EBDA',
  'EBDB',
  'EBDC',
  'EBDD',
  'EBDE',
  'EBEA',
  'EBEB',
  'EBEC',
  'EBED',
  'EBEE',
  'ECAA',
  'ECAB',
  'ECAC',
  'ECAD',
  'ECAE',
  'ECBA',
  'ECBB',
  'ECBC',
  'ECBD',
  'ECBE',
  'ECCA',
  'ECCB',
  'ECCC',
  'ECCD',
  'ECCE',
  'ECDA',
  'ECDB',
  'ECDC',
  'ECDD',
  'ECDE',
  'ECEA',
  'ECEB',
  'ECEC',
  'ECED',
  'ECEE',
  'EDAA',
  'EDAB',
  'EDAC',
  'EDAD',
  'EDAE',
  'EDBA',
  'EDBB',
  'EDBC',
  'EDBD',
  'EDBE',
  'EDCA',
  'EDCB',
  'EDCC',
  'EDCD',
  'EDCE',
  'EDDA',
  'EDDB',
  'EDDC',
  'EDDD',
  'EDDE',
  'EDEA',
  'EDEB',
  'EDEC',
  'EDED',
  'EDEE',
  'EEAA',
  'EEAB',
  'EEAC',
  'EEAD',
  'EEAE',
  'EEBA',
  'EEBB',
  'EEBC',
  'EEBD',
  'EEBE',
  'EECA',
  'EECB',
  'EECC',
  'EECD',
  'EECE',
  'EEDA',
  'EEDB',
  'EEDC',
  'EEDD',
  'EEDE',
  'EEEA',
  'EEEB',
  'EEEC',
  'EEED',
  'FFGF',
  'FFGG',
  'FFGH',
  'FFGI',
  'FFGJ',
  'FFHF',
  'FFHG',
  'FFHH',
  'FFHI',
  'FFHJ',
  'FFIF',
  'FFIG',
  'FFIH',
  'FFII',
  'FFIJ',
  'FFJF',
  'FFJG',
  'FFJH',
  'FFJI',
  'FFJJ',
  'FGFF',
  'FGFG',
  'FGFH',
  'FGFI',
  'FGFJ',
  'FGGF',
  'FGGH',
  'FGGI',
  'FGGJ',
  'FGHF',
  'FGHG',
  'FGHH',
  'FGHI',
  'FGHJ',
  'FGIF',
  'FGIG',
  'FGIH',
  'FGII',
  'FGIJ',
  'FGJF',
  'FGJG',
  'FGJH',
  'FGJI',
  'FGJJ',
  'FHFF',
  'FHFG',
  'FHFH',
  'FHFI',
  'FHFJ',
  'FHGF',
  'FHGG',
  'FHGH',
  'FHGI',
  'FHGJ',
  'FHHF',
  'FHHG',
  'FHHI',
  'FHHJ',
  'FHIF',
  'FHIG',
  'FHIH',
  'FHII',
  'FHIJ',
  'FHJF',
  'FHJG',
  'FHJH',
  'FHJI',
  'FHJJ',
  'FIFF',
  'FIFG',
  'FIFH',
  'FIFI',
  'FIFJ',
  'FIGF',
  'FIGG',
  'FIGH',
  'FIGI',
  'FIGJ',
  'FIHF',
  'FIHG',
  'FIHH',
  'FIHI',
  'FIHJ',
  'FIIF',
  'FIIG',
  'FIIH',
  'FIIJ',
  'FIJF',
  'FIJG',
  'FIJH',
  'FIJI',
  'FIJJ',
  'FJFF',
  'FJFG',
  'FJFH',
  'FJFI',
  'FJFJ',
  'FJGF',
  'FJGG',
  'FJGH',
  'FJGI',
  'FJGJ',
  'FJHF',
  'FJHG',
  'FJHH',
  'FJHI',
  'FJHJ',
  'FJIF',
  'FJIG',
  'FJIH',
  'FJII',
  'FJIJ',
  'FJJF',
  'FJJG',
  'FJJH',
  'FJJI',
  'GFFG',
  'GFFH',
  'GFFI',
  'GFFJ',
  'GFGF',
  'GFGG',
  'GFGH',
  'GFGI',
  'GFGJ',
  'GFHF',
  'GFHG',
  'GFHH',
  'GFHI',
  'GFHJ',
  'GFIF',
  'GFIG',
  'GFIH',
  'GFII',
  'GFIJ',
  'GFJF',
  'GFJG',
  'GFJH',
  'GFJI',
  'GFJJ',
  'GGFF',
  'GGFG',
  'GGFH',
  'GGFI',
  'GGFJ',
  'GGHF',
  'GGHG',
  'GGHH',
  'GGHI',
  'GGHJ',
  'GGIF',
  'GGIG',
  'GGIH',
  'GGII',
  'GGIJ',
  'GGJF',
  'GGJG',
  'GGJH',
  'GGJI',
  'GGJJ',
  'GHFF',
  'GHFG',
  'GHFH',
  'GHFI',
  'GHFJ',
  'GHGF',
  'GHGG',
  'GHGH',
  'GHGI',
  'GHGJ',
  'GHHF',
  'GHHG',
  'GHHI',
  'GHHJ',
  'GHIF',
  'GHIG',
  'GHIH',
  'GHII',
  'GHIJ',
  'GHJF',
  'GHJG',
  'GHJH',
  'GHJI',
  'GHJJ',
  'GIFF',
  'GIFG',
  'GIFH',
  'GIFI',
  'GIFJ',
  'GIGF',
  'GIGG',
  'GIGH',
  'GIGI',
  'GIGJ',
  'GIHF',
  'GIHG',
  'GIHH',
  'GIHI',
  'GIHJ',
  'GIIF',
  'GIIG',
  'GIIH',
  'GIIJ',
  'GIJF',
  'GIJG',
  'GIJH',
  'GIJI',
  'GIJJ',
  'GJFF',
  'GJFG',
  'GJFH',
  'GJFI',
  'GJFJ',
  'GJGF',
  'GJGG',
  'GJGH',
  'GJGI',
  'GJGJ',
  'GJHF',
  'GJHG',
  'GJHH',
  'GJHI',
  'GJHJ',
  'GJIF',
  'GJIG',
  'GJIH',
  'GJII',
  'GJIJ',
  'GJJF',
  'GJJG',
  'GJJH',
  'GJJI',
  'HFFG',
  'HFFH',
  'HFFI',
  'HFFJ',
  'HFGF',
  'HFGG',
  'HFGH',
  'HFGI',
  'HFGJ',
  'HFHF',
  'HFHG',
  'HFHH',
  'HFHI',
  'HFHJ',
  'HFIF',
  'HFIG',
  'HFIH',
  'HFII',
  'HFIJ',
  'HFJF',
  'HFJG',
  'HFJH',
  'HFJI',
  'HFJJ',
  'HGFF',
  'HGFG',
  'HGFH',
  'HGFI',
  'HGFJ',
  'HGGF',
  'HGGH',
  'HGGI',
  'HGGJ',
  'HGHF',
  'HGHG',
  'HGHH',
  'HGHI',
  'HGHJ',
  'HGIF',
  'HGIG',
  'HGIH',
  'HGII',
  'HGIJ',
  'HGJF',
  'HGJG',
  'HGJH',
  'HGJI',
  'HGJJ',
  'HHFF',
  'HHFG',
  'HHFH',
  'HHFI',
  'HHFJ',
  'HHGF',
  'HHGG',
  'HHGH',
  'HHGI',
  'HHGJ',
  'HHIF',
  'HHIG',
  'HHIH',
  'HHII',
  'HHIJ',
  'HHJF',
  'HHJG',
  'HHJH',
  'HHJI',
  'HHJJ',
  'HIFF',
  'HIFG',
  'HIFH',
  'HIFI',
  'HIFJ',
  'HIGF',
  'HIGG',
  'HIGH',
  'HIGI',
  'HIGJ',
  'HIHF',
  'HIHG',
  'HIHH',
  'HIHI',
  'HIHJ',
  'HIIF',
  'HIIG',
  'HIIH',
  'HIIJ',
  'HIJF',
  'HIJG',
  'HIJH',
  'HIJI',
  'HIJJ',
  'HJFF',
  'HJFG',
  'HJFH',
  'HJFI',
  'HJFJ',
  'HJGF',
  'HJGG',
  'HJGH',
  'HJGI',
  'HJGJ',
  'HJHF',
  'HJHG',
  'HJHH',
  'HJHI',
  'HJHJ',
  'HJIF',
  'HJIG',
  'HJIH',
  'HJII',
  'HJIJ',
  'HJJF',
  'HJJG',
  'HJJH',
  'HJJI',
  'IFFG',
  'IFFH',
  'IFFI',
  'IFFJ',
  'IFGF',
  'IFGG',
  'IFGH',
  'IFGI',
  'IFGJ',
  'IFHF',
  'IFHG',
  'IFHH',
  'IFHI',
  'IFHJ',
  'IFIF',
  'IFIG',
  'IFIH',
  'IFII',
  'IFIJ',
  'IFJF',
  'IFJG',
  'IFJH',
  'IFJI',
  'IFJJ',
  'IGFF',
  'IGFG',
  'IGFH',
  'IGFI',
  'IGFJ',
  'IGGF',
  'IGGH',
  'IGGI',
  'IGGJ',
  'IGHF',
  'IGHG',
  'IGHH',
  'IGHI',
  'IGHJ',
  'IGIF',
  'IGIG',
  'IGIH',
  'IGII',
  'IGIJ',
  'IGJF',
  'IGJG',
  'IGJH',
  'IGJI',
  'IGJJ',
  'IHFF',
  'IHFG',
  'IHFH',
  'IHFI',
  'IHFJ',
  'IHGF',
  'IHGG',
  'IHGH',
  'IHGI',
  'IHGJ',
  'IHHF',
  'IHHG',
  'IHHI',
  'IHHJ',
  'IHIF',
  'IHIG',
  'IHIH',
  'IHII',
  'IHIJ',
  'IHJF',
  'IHJG',
  'IHJH',
  'IHJI',
  'IHJJ',
  'IIFF',
  'IIFG',
  'IIFH',
  'IIFI',
  'IIFJ',
  'IIGF',
  'IIGG',
  'IIGH',
  'IIGI',
  'IIGJ',
  'IIHF',
  'IIHG',
  'IIHH',
  'IIHI',
  'IIHJ',
  'IIJF',
  'IIJG',
  'IIJH',
  'IIJI',
  'IIJJ',
  'IJFF',
  'IJFG',
  'IJFH',
  'IJFI',
  'IJFJ',
  'IJGF',
  'IJGG',
  'IJGH',
  'IJGI',
  'IJGJ',
  'IJHF',
  'IJHG',
  'IJHH',
  'IJHI',
  'IJHJ',
  'IJIF',
  'IJIG',
  'IJIH',
  'IJII',
  'IJIJ',
  'IJJF',
  'IJJG',
  'IJJH',
  'IJJI',
  'JFFG',
  'JFFH',
  'JFFI',
  'JFFJ',
  'JFGF',
  'JFGG',
  'JFGH',
  'JFGI',
  'JFGJ',
  'JFHF',
  'JFHG',
  'JFHH',
  'JFHI',
  'JFHJ',
  'JFIF',
  'JFIG',
  'JFIH',
  'JFII',
  'JFIJ',
  'JFJF',
  'JFJG',
  'JFJH',
  'JFJI',
  'JFJJ',
  'JGFF',
  'JGFG',
  'JGFH',
  'JGFI',
  'JGFJ',
  'JGGF',
  'JGGH',
  'JGGI',
  'JGGJ',
  'JGHF',
  'JGHG',
  'JGHH',
  'JGHI',
  'JGHJ',
  'JGIF',
  'JGIG',
  'JGIH',
  'JGII',
  'JGIJ',
  'JGJF',
  'JGJG',
  'JGJH',
  'JGJI',
  'JGJJ',
  'JHFF',
  'JHFG',
  'JHFH',
  'JHFI',
  'JHFJ',
  'JHGF',
  'JHGG',
  'JHGH',
  'JHGI',
  'JHGJ',
  'JHHF',
  'JHHG',
  'JHHI',
  'JHHJ',
  'JHIF',
  'JHIG',
  'JHIH',
  'JHII',
  'JHIJ',
  'JHJF',
  'JHJG',
  'JHJH',
  'JHJI',
  'JHJJ',
  'JIFF',
  'JIFG',
  'JIFH',
  'JIFI',
  'JIFJ',
  'JIGF',
  'JIGG',
  'JIGH',
  'JIGI',
  'JIGJ',
  'JIHF',
  'JIHG',
  'JIHH',
  'JIHI',
  'JIHJ',
  'JIIF',
  'JIIG',
  'JIIH',
  'JIIJ',
  'JIJF',
  'JIJG',
  'JIJH',
  'JIJI',
  'JIJJ',
  'JJFF',
  'JJFG',
  'JJFH',
  'JJFI',
  'JJFJ',
  'JJGF',
  'JJGG',
  'JJGH',
  'JJGI',
  'JJGJ',
  'JJHF',
  'JJHG',
  'JJHH',
  'JJHI',
  'JJHJ',
  'JJIF',
  'JJIG',
  'JJIH',
  'JJII',
  'JJIJ',
  'AAKK',
  'AALL',
  'ABKK',
  'ABLL',
  'ACKK',
  'ACLL',
  'ADKK',
  'ADLL',
  'AEKK',
  'AELL',
  'AKAK',
  'AKBK',
  'AKCK',
  'AKDK',
  'AKEK',
  'AKKA',
  'AKKB',
  'AKKC',
  'AKKD',
  'AKKE',
  'ALAL',
  'ALBL',
  'ALCL',
  'ALDL',
  'ALEL',
  'ALLA',
  'ALLB',
  'ALLC',
  'ALLD',
  'ALLE',
  'BAKK',
  'BALL',
  'BBKK',
  'BBLL',
  'BCKK',
  'BCLL',
  'BDKK',
  'BDLL',
  'BEKK',
  'BELL',
  'BKAK',
  'BKBK',
  'BKCK',
  'BKDK',
  'BKEK',
  'BKKA',
  'BKKB',
  'BKKC',
  'BKKD',
  'BKKE',
  'BLAL',
  'BLBL',
  'BLCL',
  'BLDL',
  'BLEL',
  'BLLA',
  'BLLB',
  'BLLC',
  'BLLD',
  'BLLE',
  'CAKK',
  'CALL',
  'CBKK',
  'CBLL',
  'CCKK',
  'CCLL',
  'CDKK',
  'CDLL',
  'CEKK',
  'CELL',
  'CKAK',
  'CKBK',
  'CKCK',
  'CKDK',
  'CKEK',
  'CKKA',
  'CKKB',
  'CKKC',
  'CKKD',
  'CKKE',
  'CLAL',
  'CLBL',
  'CLCL',
  'CLDL',
  'CLEL',
  'CLLA',
  'CLLB',
  'CLLC',
  'CLLD',
  'CLLE',
  'DAKK',
  'DALL',
  'DBKK',
  'DBLL',
  'DCKK',
  'DCLL',
  'DDKK',
  'DDLL',
  'DEKK',
  'DELL',
  'DKAK',
  'DKBK',
  'DKCK',
  'DKDK',
  'DKEK',
  'DKKA',
  'DKKB',
  'DKKC',
  'DKKD',
  'DKKE',
  'DLAL',
  'DLBL',
  'DLCL',
  'DLDL',
  'DLEL',
  'DLLA',
  'DLLB',
  'DLLC',
  'DLLD',
  'DLLE',
  'EAKK',
  'EALL',
  'EBKK',
  'EBLL',
  'ECKK',
  'ECLL',
  'EDKK',
  'EDLL',
  'EEKK',
  'EELL',
  'EKAK',
  'EKBK',
  'EKCK',
  'EKDK',
  'EKEK',
  'EKKA',
  'EKKB',
  'EKKC',
  'EKKD',
  'EKKE',
  'ELAL',
  'ELBL',
  'ELCL',
  'ELDL',
  'ELEL',
  'ELLA',
  'ELLB',
  'ELLC',
  'ELLD',
  'ELLE',
  'FFKK',
  'FFLL',
  'FGKK',
  'FGLL',
  'FHKK',
  'FHLL',
  'FIKK',
  'FILL',
  'FJKK',
  'FJLL',
  'FKFK',
  'FKGK',
  'FKHK',
  'FKIK',
  'FKJK',
  'FKKF',
  'FKKG',
  'FKKH',
  'FKKI',
  'FKKJ',
  'FLFL',
  'FLGL',
  'FLHL',
  'FLIL',
  'FLJL',
  'FLLF',
  'FLLG',
  'FLLH',
  'FLLI',
  'FLLJ',
  'GFKK',
  'GFLL',
  'GGKK',
  'GGLL',
  'GHKK',
  'GHLL',
  'GIKK',
  'GILL',
  'GJKK',
  'GJLL',
  'GKFK',
  'GKGK',
  'GKHK',
  'GKIK',
  'GKJK',
  'GKKF',
  'GKKG',
  'GKKH',
  'GKKI',
  'GKKJ',
  'GLFL',
  'GLGL',
  'GLHL',
  'GLIL',
  'GLJL',
  'GLLF',
  'GLLG',
  'GLLH',
  'GLLI',
  'GLLJ',
  'HFKK',
  'HFLL',
  'HGKK',
  'HGLL',
  'HHKK',
  'HHLL',
  'HIKK',
  'HILL',
  'HJKK',
  'HJLL',
  'HKFK',
  'HKGK',
  'HKHK',
  'HKIK',
  'HKJK',
  'HKKF',
  'HKKG',
  'HKKH',
  'HKKI',
  'HKKJ',
  'HLFL',
  'HLGL',
  'HLHL',
  'HLIL',
  'HLJL',
  'HLLF',
  'HLLG',
  'HLLH',
  'HLLI',
  'HLLJ',
  'IFKK',
  'IFLL',
  'IGKK',
  'IGLL',
  'IHKK',
  'IHLL',
  'IIKK',
  'IILL',
  'IJKK',
  'IJLL',
  'IKFK',
  'IKGK',
  'IKHK',
  'IKIK',
  'IKJK',
  'IKKF',
  'IKKG',
  'IKKH',
  'IKKI',
  'IKKJ',
  'ILFL',
  'ILGL',
  'ILHL',
  'ILIL',
  'ILJL',
  'ILLF',
  'ILLG',
  'ILLH',
  'ILLI',
  'ILLJ',
  'JFKK',
  'JFLL',
  'JGKK',
  'JGLL',
  'JHKK',
  'JHLL',
  'JIKK',
  'JILL',
  'JJKK',
  'JJLL',
  'JKFK',
  'JKGK',
  'JKHK',
  'JKIK',
  'JKJK',
  'JKKF',
  'JKKG',
  'JKKH',
  'JKKI',
  'JKKJ',
  'JLFL',
  'JLGL',
  'JLHL',
  'JLIL',
  'JLJL',
  'JLLF',
  'JLLG',
  'JLLH',
  'JLLI',
  'JLLJ',
  'KAAK',
  'KABK',
  'KACK',
  'KADK',
  'KAEK',
  'KAKA',
  'KAKB',
  'KAKC',
  'KAKD',
  'KAKE',
  'KBAK',
  'KBBK',
  'KBCK',
  'KBDK',
  'KBEK',
  'KBKA',
  'KBKB',
  'KBKC',
  'KBKD',
  'KBKE',
  'KCAK',
  'KCBK',
  'KCCK',
  'KCDK',
  'KCEK',
  'KCKA',
  'KCKB',
  'KCKC',
  'KCKD',
  'KCKE',
  'KDAK',
  'KDBK',
  'KDCK',
  'KDDK',
  'KDEK',
  'KDKA',
  'KDKB',
  'KDKC',
  'KDKD',
  'KDKE',
  'KEAK',
  'KEBK',
  'KECK',
  'KEDK',
  'KEEK',
  'KEKA',
  'KEKB',
  'KEKC',
  'KEKD',
  'KEKE',
  'KFFK',
  'KFGK',
  'KFHK',
  'KFIK',
  'KFJK',
  'KFKF',
  'KFKG',
  'KFKH',
  'KFKI',
  'KFKJ',
  'KGFK',
  'KGGK',
  'KGHK',
  'KGIK',
  'KGJK',
  'KGKF',
  'KGKG',
  'KGKH',
  'KGKI',
  'KGKJ',
  'KHFK',
  'KHGK',
  'KHHK',
  'KHIK',
  'KHJK',
  'KHKF',
  'KHKG',
  'KHKH',
  'KHKI',
  'KHKJ',
  'KIFK',
  'KIGK',
  'KIHK',
  'KIIK',
  'KIJK',
  'KIKF',
  'KIKG',
  'KIKH',
  'KIKI',
  'KIKJ',
  'KJFK',
  'KJGK',
  'KJHK',
  'KJIK',
  'KJJK',
  'KJKF',
  'KJKG',
  'KJKH',
  'KJKI',
  'KJKJ',
  'KKAA',
  'KKAB',
  'KKAC',
  'KKAD',
  'KKAE',
  'KKBA',
  'KKBB',
  'KKBC',
  'KKBD',
  'KKBE',
  'KKCA',
  'KKCB',
  'KKCC',
  'KKCD',
  'KKCE',
  'KKDA',
  'KKDB',
  'KKDC',
  'KKDD',
  'KKDE',
  'KKEA',
  'KKEB',
  'KKEC',
  'KKED',
  'KKEE',
  'KKFF',
  'KKFG',
  'KKFH',
  'KKFI',
  'KKFJ',
  'KKGF',
  'KKGG',
  'KKGH',
  'KKGI',
  'KKGJ',
  'KKHF',
  'KKHG',
  'KKHH',
  'KKHI',
  'KKHJ',
  'KKIF',
  'KKIG',
  'KKIH',
  'KKII',
  'KKIJ',
  'KKJF',
  'KKJG',
  'KKJH',
  'KKJI',
  'KKJJ',
  'LAAL',
  'LABL',
  'LACL',
  'LADL',
  'LAEL',
  'LALA',
  'LALB',
  'LALC',
  'LALD',
  'LALE',
  'LBAL',
  'LBBL',
  'LBCL',
  'LBDL',
  'LBEL',
  'LBLA',
  'LBLB',
  'LBLC',
  'LBLD',
  'LBLE',
  'LCAL',
  'LCBL',
  'LCCL',
  'LCDL',
  'LCEL',
  'LCLA',
  'LCLB',
  'LCLC',
  'LCLD',
  'LCLE',
  'LDAL',
  'LDBL',
  'LDCL',
  'LDDL',
  'LDEL',
  'LDLA',
  'LDLB',
  'LDLC',
  'LDLD',
  'LDLE',
  'LEAL',
  'LEBL',
  'LECL',
  'LEDL',
  'LEEL',
  'LELA',
  'LELB',
  'LELC',
  'LELD',
  'LELE',
  'LFFL',
  'LFGL',
  'LFHL',
  'LFIL',
  'LFJL',
  'LFLF',
  'LFLG',
  'LFLH',
  'LFLI',
  'LFLJ',
  'LGFL',
  'LGGL',
  'LGHL',
  'LGIL',
  'LGJL',
  'LGLF',
  'LGLG',
  'LGLH',
  'LGLI',
  'LGLJ',
  'LHFL',
  'LHGL',
  'LHHL',
  'LHIL',
  'LHJL',
  'LHLF',
  'LHLG',
  'LHLH',
  'LHLI',
  'LHLJ',
  'LIFL',
  'LIGL',
  'LIHL',
  'LIIL',
  'LIJL',
  'LILF',
  'LILG',
  'LILH',
  'LILI',
  'LILJ',
  'LJFL',
  'LJGL',
  'LJHL',
  'LJIL',
  'LJJL',
  'LJLF',
  'LJLG',
  'LJLH',
  'LJLI',
  'LJLJ',
  'LLAA',
  'LLAB',
  'LLAC',
  'LLAD',
  'LLAE',
  'LLBA',
  'LLBB',
  'LLBC',
  'LLBD',
  'LLBE',
  'LLCA',
  'LLCB',
  'LLCC',
  'LLCD',
  'LLCE',
  'LLDA',
  'LLDB',
  'LLDC',
  'LLDD',
  'LLDE',
  'LLEA',
  'LLEB',
  'LLEC',
  'LLED',
  'LLEE',
  'LLFF',
  'LLFG',
  'LLFH',
  'LLFI',
  'LLFJ',
  'LLGF',
  'LLGG',
  'LLGH',
  'LLGI',
  'LLGJ',
  'LLHF',
  'LLHG',
  'LLHH',
  'LLHI',
  'LLHJ',
  'LLIF',
  'LLIG',
  'LLIH',
  'LLII',
  'LLIJ',
  'LLJF',
  'LLJG',
  'LLJH',
  'LLJI',
  'LLJJ',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKKL',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLK',
  'KKLL',
  'KKLL',
  'KKLL',
  'KKLL',
  'KKLL',
  'KKLL',
  'KKLL',
  'KKLL',
  'KKLL',
  'KKLL',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKK',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLKL',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLK',
  'KLLL',
  'KLLL',
  'KLLL',
  'KLLL',
  'KLLL',
  'KLLL',
  'KLLL',
  'KLLL',
  'KLLL',
  'KLLL',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKK',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKKL',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLK',
  'LKLL',
  'LKLL',
  'LKLL',
  'LKLL',
  'LKLL',
  'LKLL',
  'LKLL',
  'LKLL',
  'LKLL',
  'LKLL',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKK',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLKL',
  'LLLK',
  'LLLK',
  'LLLK',
  'LLLK',
  'LLLK',
  'LLLK',
  'LLLK',
  'LLLK',
  'LLLK',
  'LLLK',
  'AAAA',
  'AAAA',
  'AAAA',
  'AAAA',
  'AAAA',
  'BBBB',
  'BBBB',
  'BBBB',
  'BBBB',
  'BBBB',
  'CCCC',
  'CCCC',
  'CCCC',
  'CCCC',
  'CCCC',
  'DDDD',
  'DDDD',
  'DDDD',
  'DDDD',
  'DDDD',
  'EEEE',
  'EEEE',
  'EEEE',
  'EEEE',
  'EEEE',
  'FFFF',
  'FFFF',
  'FFFF',
  'FFFF',
  'FFFF',
  'GGGG',
  'GGGG',
  'GGGG',
  'GGGG',
  'GGGG',
  'HHHH',
  'HHHH',
  'HHHH',
  'HHHH',
  'HHHH',
  'IIII',
  'IIII',
  'IIII',
  'IIII',
  'IIII',
  'JJJJ',
  'JJJJ',
  'JJJJ',
  'JJJJ',
  'JJJJ',
  'KKKK',
  'KKKK',
  'KKKK',
  'KKKK',
  'KKKK',
  'LLLL',
  'LLLL',
  'LLLL',
  'LLLL',
  'LLLL'
];
