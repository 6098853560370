import type { PageProps } from 'gatsby';
import React from 'react';

import { Seo } from '@/components';

const IndexPage: React.FC<PageProps> = () => {
  return (
    <div className="flex h-screen items-center justify-center">Not Found</div>
  );
};
export const Head = () => <Seo title="Not Found" />;
export default IndexPage;
