import { StaticImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { useContractRead } from 'wagmi';

import SoldOutSVG from '@/assets/images/more/soldout.inline.svg';
import { Button } from '@/components';
import { EMintType, goTo2048GamePage } from '@/pages/games/2048';
import { currentContractConfig } from '@/utils/const';
export function SectionMint() {
  const { data: amount } = useContractRead({
    abi: [
      {
        inputs: [],
        name: 'queryClaimedCount',
        outputs: [
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      }
    ],
    functionName: 'queryClaimedCount',
    address: currentContractConfig.contractAddress as any,
    enabled: true
  });

  const configs = useMemo(
    () => [
      {
        content: 2000,
        label: 'Total Supply'
      },
      {
        content: (
          <span>
            <span className="text-[#FF71FD]">{(amount as any) || 0}</span>/2000
          </span>
        ),
        label: 'Claimed Count'
      },
      {
        content: `${currentContractConfig.unitPrice} ${currentContractConfig.currency}`,
        label: 'Price'
      }
    ],
    [amount]
  );

  const isSoldOut = useMemo(() => amount === 2000, [amount]);
  return (
    <section className="page-container pt-32">
      <h1 className="mb-8 text-center text-3xl sm:mb-16 sm:mt-4 sm:text-[48px]">
        Mint Your 2048.ink Now
      </h1>
      <div className="mb-14 grid grid-cols-1 gap-x-[2.4%] gap-y-5 sm:grid-cols-3">
        {configs.map((item) => (
          <div
            key={item.label}
            className="mx-auto flex w-full max-w-none flex-col items-center justify-center gap-y-3 rounded-[14px] border-[1px] border-solid border-[#3F4E65] bg-[#252E3C] py-[18px] sm:max-w-none"
          >
            <div className="text-2xl sm:text-[36px]">{item.content}</div>
            <span className="text-xs text-[#C1CFE5]">{item.label}</span>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 gap-x-[6.38%] gap-y-6 md:grid-cols-2">
        <div className="relative mx-auto aspect-[1.039] w-full max-w-[484px]">
          {isSoldOut && (
            <SoldOutSVG className="absolute right-0 top-0 z-10 aspect-square !h-auto w-[70%]" />
          )}
          <StaticImage
            src="../../../assets/images/more/left_bg.png"
            objectFit="cover"
            alt="bg"
          />
          <p className="absolute bottom-[73.55%] mx-[4.75%] line-clamp-1 font-BaiJamjuree text-sm font-bold leading-[1.56] sm:text-base lg:text-lg">
            If you choose to mint directly, your odds are:
          </p>
          <div className="absolute left-[4.75%] right-[4.75%] top-[27.62%]">
            <StaticImage
              src="../../../assets/images/more/left_form.svg"
              objectFit="contain"
              quality={100}
              alt="form"
            />
          </div>
          <Button
            onClick={() => {
              goTo2048GamePage(EMintType.DIRECTLY);
            }}
            className="absolute bottom-[7.2%] left-[20%] aspect-[4.53] w-[60%]"
          >
            Mint Directly
          </Button>
        </div>
        <div className="relative mx-auto aspect-[1.039] w-full max-w-[484px]">
          {isSoldOut && (
            <SoldOutSVG className="absolute right-0 top-0 z-10 aspect-square !h-auto w-[70%]" />
          )}
          <StaticImage
            src="../../../assets/images/more/right_bg.png"
            objectFit="cover"
            alt="bg"
            quality={100}
          />
          <p className="absolute bottom-[73.55%] mx-[4.75%] line-clamp-3 font-BaiJamjuree text-sm font-bold leading-[1.56] sm:text-base lg:text-lg">
            If you Play to Mint, you can increase your chance of minting a rare
            token! The higher the score, the better the chance:
          </p>
          <div className="absolute left-[4.75%] right-[4.75%] top-[27.62%]">
            <StaticImage
              src="../../../assets/images/more/right_form.svg"
              quality={100}
              objectFit="contain"
              alt="form"
            />
          </div>
          <Button
            onClick={() => {
              goTo2048GamePage(EMintType.PlAY);
            }}
            className="absolute bottom-[7.2%] left-[20%] aspect-[4.53] w-[60%]"
          >
            Play to Mint
          </Button>
        </div>
      </div>
    </section>
  );
}
