import { useMemo } from 'react';
import { useWindowSize } from 'react-use';

export function useSize(mobileWidth?: number) {
  const { width, height } = useWindowSize();

  const isMIN = useMemo(() => {
    if (width === undefined || width === 0) {
      return true;
    }
    return width < 640;
  }, [width]);

  const isSM = useMemo(() => {
    return width >= 640 && width < 768;
  }, [width]);
  const isBiggerThanSM = useMemo(() => width >= 640, [width]);

  const isMD = useMemo(() => {
    return width >= 768 && width < 1024;
  }, [width]);
  const isBiggerThanMD = useMemo(() => width >= 768, [width]);

  const isLG = useMemo(() => {
    return width >= 1024 && width < 1280;
  }, [width]);
  const isBiggerThanLG = useMemo(() => width >= 1024, [width]);

  const isXL = useMemo(() => {
    return width >= 1280 && width < 1440;
  }, [width]);
  const isBiggerThanXL = useMemo(() => width >= 1280, [width]);

  const is2XL = useMemo(() => {
    return width >= 1440 && width < 1600;
  }, [width]);
  const isBiggerThan2XL = useMemo(() => width >= 1440, [width]);

  const is3XL = useMemo(() => {
    return width >= 1600 && width < 2000;
  }, [width]);
  const isBiggerThan3XL = useMemo(() => width >= 1600, [width]);

  const is4XL = useMemo(() => {
    return width >= 2000;
  }, [width]);
  const isBiggerThan4XL = useMemo(() => width >= 2000, [width]);

  /**
   * 通过屏幕宽度是否mobile
   */
  const isMobile = useMemo(
    () => width < (mobileWidth || 1024),
    [mobileWidth, width]
  );
  return {
    isMIN,
    isSM,
    isMD,
    isLG,
    isXL,
    is2XL,
    is3XL,
    is4XL,
    isMobile,
    isBiggerThanSM,
    isBiggerThanMD,
    isBiggerThanLG,
    isBiggerThanXL,
    isBiggerThan2XL,
    isBiggerThan3XL,
    isBiggerThan4XL,
    width,
    height
  };
}
