import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn, useLocalStorage } from 'react-use';
import { useAccount } from 'wagmi';

import srImg from '@/assets/images/more/sr.svg';
import ssrImg from '@/assets/images/more/ssr.svg';
import { Button } from '@/components';
import api from '@/services/api';
import { useAppStore } from '@/store/appStore';
import { GameState, Tiles } from '@/types/global';
import { getSrAndSsrRarityValueByScore } from '@/utils/const';

import { Modal } from '../Modal';
import { Game } from './Game';
import { GameBtns } from './GameBtns';
import { Score } from './Score';
interface IGamePlayProps {
  className?: string;
}

export const gameSize = 4;

export function GamePlay(props: IGamePlayProps) {
  const { className } = props;
  const { address } = useAccount();
  const [showIntro, setShowIntro] = useLocalStorage<boolean>(
    'show-intro',
    true
  );

  const {
    gameSeed,
    gameMoves,
    setGameMoves,
    setPlayMintSignature,
    isInitialized
  } = useAppStore();
  const [gameState, setGameState] = useState<GameState | null>(null);
  const { score, setScore } = useAppStore();
  const [tiles, setTiles] = useState<number[][]>([]);
  const [gameId, setGame] = useState('');

  const [showCongratulations, setShowCongratulations] = useState(false);

  const newTile = useMemo(() => {
    if (gameState) {
      const newTile = gameState.board.tiles
        .flat()
        .find((item) => item?.id === gameState.board.id_counter - 1);
      if (newTile) {
        return newTile;
      }
    }
  }, [gameState]);

  useEffect(() => {
    if (gameSeed === undefined || !isInitialized) return;
    try {
      const id = window.game.serialize({
        V2: {
          version: 2,
          seed: gameSeed,
          width: gameSize,
          height: gameSize,
          moves: gameMoves
        }
      });
      setGame(id);
      const state = window.game.get_gamestate(id);
      setGameState(state);
      setScore(state.score_current);
      setTiles(formateTiles(state.board.tiles));
    } catch (e) {
      console.error(e);
      toast.error('Game data error. Please exit the game!');
    }
  }, [gameMoves, gameSeed, isInitialized, setScore]);

  useEffect(() => {
    if (gameState?.won) {
      setShowCongratulations(true);
    }
    if (gameState?.over) {
      //如果ui刷新不及时，可以给个延迟，让ui刷新，最后一个tile出现
      setShowCongratulations(true);
    }
  }, [gameState?.over, gameState?.won, setGameMoves]);
  useEffect(() => {
    if (!isInitialized || showIntro) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          if (gameState && gameState.allowed_moves.includes('UP')) {
            setGameMoves([...gameMoves, 'UP']);
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (gameState && gameState.allowed_moves.includes('DOWN')) {
            setGameMoves([...gameMoves, 'DOWN']);
          }
          break;
        case 'ArrowLeft':
          event.preventDefault();

          if (gameState && gameState.allowed_moves.includes('LEFT')) {
            setGameMoves([...gameMoves, 'LEFT']);
          }
          break;
        case 'ArrowRight':
          event.preventDefault();
          if (gameState && gameState.allowed_moves.includes('RIGHT')) {
            setGameMoves([...gameMoves, 'RIGHT']);
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [gameMoves, gameState, isInitialized, setGameMoves, showIntro]);

  const [mintResult, doMint] = useAsyncFn(async () => {
    const signature = await api.verifierController.submitResultUsingPost({
      address,
      data: gameId
    });
    setPlayMintSignature(signature);
    setShowCongratulations(false);
  }, [address, gameId, setPlayMintSignature, setShowCongratulations]);

  if (!isInitialized) return null;

  return (
    <div
      className={cn(
        className,
        'flex flex-col items-center 3xl:!absolute 3xl:top-1/2 3xl:mt-0 3xl:-translate-y-1/2'
      )}
    >
      <Score value={score} />
      <Game {...{ tiles, newTile, showIntro, setShowIntro }} />
      <GameBtns />
      {showCongratulations && (
        <div className="fixed inset-x-0 inset-y-0 flex items-center justify-center sm:-inset-x-20">
          <Modal
            title="Congratulations!"
            onClose={() => {
              setShowCongratulations(false);
              setGameMoves([]);
            }}
          >
            <p className="mb-3 text-center text-lg text-[#C1CFE5] sm:mb-7 sm:text-xl">
              Your score: <span className="text-[#FF71FD]">{score}</span>
            </p>
            <p className="mb-6 text-center font-BaiJamjuree text-base font-medium text-[#C1CFE5] sm:mb-[52px] sm:text-lg">
              You have a{' '}
              <span className="font-BakbakOne font-normal text-[#FF71FD]">
                {getSrAndSsrRarityValueByScore(score || 0)}
              </span>{' '}
              chance of drawing tokens of{' '}
              <img src={srImg} className="inline-block w-7 sm:w-9" /> or{' '}
              <img src={ssrImg} className="inline-block w-7 sm:w-9" /> rarity.
              Luck is on your side, so go mint!
            </p>
            <div
              className={cn(
                className,
                'mb-2 flex w-full items-center gap-x-[4.6%]'
              )}
            >
              <Button
                className="h-12 w-[46.8%] sm:!text-xl"
                loading={mintResult.loading}
                onClick={doMint}
              >
                Mint
              </Button>
              <Button
                onClick={() => {
                  setShowCongratulations(false);
                  setGameMoves([]);
                }}
                className="h-12 w-[46.8%] !bg-[linear-gradient(92deg,#364254_0.69%,#364254_62%)] hover:!opacity-90 sm:!text-xl"
              >
                Try Again
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

function formateTiles(tiles: Tiles) {
  const tempTiles = tiles
    .slice(0, gameSize)
    .map((item) => item.slice(0, gameSize));
  return tempTiles.map((item) =>
    item.map((innerItem) => innerItem?.value || 0)
  );
}
