import common from './common';
import footer from './footer';
import header from './header';
import home from './home';
import menu from './menu';

export default {
  ...header,
  ...footer,
  ...home,
  ...menu,
  ...common
};
