// @ts-ignore
/* eslint-disable */
import request from '../request';

/** signForSeed POST /v1/verifier/sign */
export async function signForSeedUsingPost(
  body: API.MetaMaskRequest,
  options?: { [key: string]: any },
) {
  return request<string>('/v1/verifier/sign', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** submitResult POST /v1/verifier/submit */
export async function submitResultUsingPost(
  body: API.SubmitResultRequest,
  options?: { [key: string]: any },
) {
  return request<string>('/v1/verifier/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
