import { CHAIN_ENV } from 'gatsby-env-variables';

export const slogan = '2048.ink is now live!';
export enum ERouterPaths {
  GAMES = '/games',
  LEARN_MORE = '/mint',
  GAME_2048 = `/games/2048`
}
export enum EWalletTypes {
  METAMASK = 'MetaMask',
  TRUST = 'TrustWallet'
}

export enum ERarity {
  N = 'N',
  R = 'R',
  SR = 'SR',
  SSR = 'SSR'
}

enum EScoreLevel {
  NO_SCORE = 'NO_SCORE',
  LEVEL_ONE = 'LEVEL_ONE',
  LEVEL_TWO = 'LEVEL_TWO',
  LEVEL_THREE = 'LEVEL_THREE',
  LEVEL_FOUR = 'LEVEL_FOUR'
}

const ScoreRarityConfigMap: Record<
  EScoreLevel,
  { min?: number; max?: number; rarity: Record<ERarity, number> }
> = {
  [EScoreLevel.NO_SCORE]: {
    rarity: {
      [ERarity.N]: 0.63,
      [ERarity.R]: 0.3,
      [ERarity.SR]: 0.05,
      [ERarity.SSR]: 0.02
    }
  },
  [EScoreLevel.LEVEL_ONE]: {
    min: 0,
    max: 10000,
    rarity: {
      [ERarity.N]: 0.55,
      [ERarity.R]: 0.32,
      [ERarity.SR]: 0.09,
      [ERarity.SSR]: 0.04
    }
  },
  [EScoreLevel.LEVEL_TWO]: {
    min: 10000,
    max: 15000,
    rarity: {
      [ERarity.N]: 0.5,
      [ERarity.R]: 0.32,
      [ERarity.SR]: 0.12,
      [ERarity.SSR]: 0.06
    }
  },
  [EScoreLevel.LEVEL_THREE]: {
    min: 15000,
    max: 20000,
    rarity: {
      [ERarity.N]: 0.45,
      [ERarity.R]: 0.32,
      [ERarity.SR]: 0.15,
      [ERarity.SSR]: 0.08
    }
  },
  [EScoreLevel.LEVEL_FOUR]: {
    min: 20000, // no max
    rarity: {
      [ERarity.N]: 0.4,
      [ERarity.R]: 0.3,
      [ERarity.SR]: 0.2,
      [ERarity.SSR]: 0.1
    }
  }
};

function getLevelByScore(score?: number) {
  if (!score) {
    return EScoreLevel.NO_SCORE;
  }
  const keys = Object.keys(ScoreRarityConfigMap) as EScoreLevel[];
  for (const key of keys) {
    const config = ScoreRarityConfigMap[key];
    const { min, max } = config;
    if (max === undefined && min === undefined) {
      //no score
      continue;
    }
    if (max === undefined && min !== undefined) {
      if (score >= min) {
        return key;
      }
    }
    if (max !== undefined && min !== undefined && score >= min && score < max) {
      return key;
    }
  }
  return EScoreLevel.LEVEL_ONE;
}

export function getSrAndSsrRarityValueByScore(score: number) {
  const config = ScoreRarityConfigMap[getLevelByScore(score)];
  const value = config.rarity.SR + config.rarity.SSR;
  return `${Math.round(value * 100)}%`;
}

export const contractConfigs = {
  testnet: {
    // contractAddress: '0x17F9dEF261d9e27eA88Dd92bb1De902588D4120D'
    contractAddress: '0x484d52568dBA12C14A328cD7c015502fc8dF5dAa',
    unitPrice: 0.01,
    currency: 'MATIC'
  },
  mainnet: {
    contractAddress: '0xcF65e59CF0BdE1b2EE9B066A41ae118323bA5844',
    unitPrice: 0.08,
    currency: 'BNB'
  }
};

export const currentContractConfig = contractConfigs[CHAIN_ENV];

export const isTestnet = CHAIN_ENV === 'testnet';
