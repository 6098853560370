import cn from 'classnames';
import React from 'react';
interface ISplitProps {
  className?: string;
}
export function Split(props: ISplitProps) {
  const { className } = props;
  return (
    <div
      className={cn(
        'h-[1px] w-full bg-[url("/split.svg")] bg-repeat-x',
        className
      )}
    ></div>
  );
}
