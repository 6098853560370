import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { ToastContainer } from 'react-toastify';

import { I18nextProvider } from '@/i18n';
import { useGameInit } from '@/utils/hooks';

import { ModalLayout } from './Layout/ModalLayout';
import { WagmiProvider } from './WagmiProvider';

interface RootElementProps {
  children: JSX.Element;
}

export function RootElement(props: RootElementProps) {
  const { children } = props;
  useGameInit();
  return (
    <WagmiProvider>
      <ToastContainer />
      <ModalLayout>
        <I18nextProvider>{children}</I18nextProvider>
      </ModalLayout>
    </WagmiProvider>
  );
}
