import type { PageProps } from 'gatsby';
import React from 'react';

import { SectionFirstScreen, SectionInscription, Seo } from '@/components';

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <div id="FirstScreen">
        <SectionFirstScreen />
      </div>
      <SectionInscription />
    </>
  );
};
export const Head = () => <Seo title="" />;
export default IndexPage;
