import cn from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { useMount } from 'react-use';

import { Tile } from '@/types/global';
export const awsBaseUrl =
  'https://inscription-2048-frontend.s3.ap-northeast-1.amazonaws.com/';
const chessConfig = 'KKKK';
const chessBgUrl = `${awsBaseUrl}${chessConfig[0]}_Chessboard_BG.png`;
const frameBgUrl = `${awsBaseUrl}${chessConfig[3]}_Frame.png`;
const tileDefaultBgUrl = `${awsBaseUrl}${chessConfig[0]}_Tile_BG_Default.png`;

interface IGameProps {
  className?: string;
  tiles: number[][];
  newTile?: Tile;
  showIntro?: boolean;
  setShowIntro: (value?: boolean) => void;
}

export function Game(props: IGameProps) {
  const { className, tiles, newTile, showIntro, setShowIntro } = props;

  function preloadImages(imageArray: string[]) {
    if (typeof window === undefined) return;
    for (let i = 0; i < imageArray.length; i++) {
      const img = new Image();
      img.src = imageArray[i];
    }
  }

  function prefetchAssets(chessConfig: string) {
    const nums = [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];
    const tileBgs = nums.map((num) => {
      return `${awsBaseUrl}${chessConfig[1]}_Tile_BG_${num}.png`;
    });
    const fonts = nums.map((num) => {
      return `${awsBaseUrl}${chessConfig[2]}_Font_${num}.png`;
    });
    preloadImages([...tileBgs, ...fonts]);
  }

  useMount(() => {
    prefetchAssets(chessConfig);
  });

  useEffect(() => {
    if (!newTile) return;
    const id = `scale-container-${newTile.y * 4 + newTile.x}`;
    const ele = document.getElementById(id);
    if (ele) {
      ele.style.animation = 'newTileAnimation 0.3s';
      ele.style.display = 'block';
      ele.addEventListener('animationend', () => {
        ele.style.animation = '';
      });
    }
  }, [newTile]);

  return (
    <div
      className={cn(
        className,
        'drag-none relative mb-8 mt-10 aspect-square w-[calc(100vw_-_32px)] max-w-[533px] select-none bg-cover bg-center 3xl:max-w-[533px]'
      )}
      style={{
        backgroundImage: `url('${frameBgUrl}')`
      }}
    >
      {/* 棋子背景 */}
      <div
        style={{
          backgroundImage: `url('${chessBgUrl}')`
        }}
        className="drag-none absolute left-[1.33%] top-[1.33%] grid aspect-square w-[97.34%] select-none grid-cols-4 gap-x-[1.17%] gap-y-[0.98%] overflow-hidden rounded-lg bg-cover p-[3.32%]"
      >
        {tiles.flat().map((tile, index) => {
          let isNew = false;
          if (newTile) {
            isNew = index === newTile.y * 4 + newTile.x;
          }
          const tileBgUrl = `${awsBaseUrl}${chessConfig[1]}_Tile_BG_${tile}.png`;
          const fontImgUrl = `${awsBaseUrl}${chessConfig[2]}_Font_${tile}.png`;
          const containerId = `scale-container-${index}`;
          return (
            <div
              key={index}
              className="drag-none relative flex aspect-square w-full select-none items-center bg-cover bg-center"
              style={{
                backgroundImage: `url('${tileDefaultBgUrl}')`
              }}
            >
              <div id={containerId} className={cn(isNew ? 'hidden' : 'block')}>
                {tile > 0 && (
                  <img
                    src={tileBgUrl}
                    className="drag-none w-full select-none"
                  />
                )}
                {tile > 0 && (
                  <img
                    src={fontImgUrl}
                    className="drag-none absolute left-1/2 top-1/2 h-[23.5%] -translate-x-1/2 -translate-y-1/2 select-none"
                  />
                )}
              </div>
            </div>
          );
        })}
        {showIntro && (
          <div className="absolute inset-0 flex select-text flex-col items-center justify-center rounded-3xl bg-[#09071A] bg-opacity-50 px-8">
            <div className="aspect-square w-40 sm:w-[224px]">
              <StaticImage
                src="../../../../assets/images/more/intro.png"
                alt="direction"
                objectFit="cover"
              />
            </div>

            <p className="mb-3 mt-4 text-center text-base sm:mb-7 sm:mt-[30px]">
              Combine tiles with the same number to reach the tile with the
              value of 2048.
              <br />
              Use the{' '}
              <span className="text-[#FF71FD]">Up, Down, Left, Right</span>{' '}
              arrow keys to move tiles.
            </p>
            <span
              onClick={() => {
                setShowIntro(false);
              }}
              className="cursor-pointer text-center text-xl text-[#FF71FD] underline duration-200 hover:opacity-75"
            >
              Skip
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
