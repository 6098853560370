import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { useRef } from 'react';

import * as styles from '@/styles/text-3d.module.css';
// import FloatingShape from '@/components/floatingShape';
import { ERouterPaths, slogan } from '@/utils/const';

import Text3d from './Text3D';

export function SectionFirstScreen() {
  const plane = useRef<HTMLDivElement>(null);
  const maxRotate = 45;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const manageMouseMove = (e: any) => {
    const x = e.clientX / window.innerWidth;
    const y = e.clientY / window.innerHeight;
    const perspective = window.innerWidth * 4;
    const rotateX = maxRotate * x - maxRotate / 2;
    const rotateY = (maxRotate * y - maxRotate / 2) * -1;
    if (plane.current) {
      plane.current.style.transform = `perspective(${perspective}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg)`;
    }
  };

  const handleMouseLeave = () => {
    if (plane.current) {
      plane.current.style.transform = ``;
    }
  };
  return (
    <div className="relative h-[100vh] w-full sm:h-[100vh]">
      <div className='absolute inset-0 bg-[url("/bg/bg.png")] bg-cover bg-[center_top]'></div>
      <div className='absolute left-1/2 top-1/2 hidden aspect-[1.2] w-[50%] -translate-x-1/2 -translate-y-[46%] bg-[url("/bg/word.png")] bg-contain bg-[center_top] bg-no-repeat sm:block'></div>
      <div className='absolute inset-0 bg-[url("/bg/mock.png")] bg-cover bg-[center_top]'></div>
      <div
        className={cn(
          'absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center px-4 text-center sm:px-8 lg:px-[54px]'
        )}
      >
        <div className="mb-[28px] max-w-[899px] text-[18px] leading-[26px] text-[#E2E3FF] md:mb-[40px] md:text-[28px] md:leading-[40px]">
          <div>Unleash the Fun, Mint Your Game</div>
        </div>
        <div
          onMouseMove={manageMouseMove}
          onMouseLeave={handleMouseLeave}
          className="relative hidden h-[160px] w-full justify-center md:flex"
        >
          <div ref={plane} className={styles.body}>
            <Text3d primary={slogan} secondary={slogan} />
          </div>
        </div>

        <div className="block text-[40px] md:hidden">{slogan}</div>

        <button
          type="button"
          className={cn(
            'mt-[50px] rounded-[10px] bg-gradient-to-r from-[#FF30D5] to-[#2E32FF] px-12 py-3 text-xl leading-[28px] text-[#FFFFFF] duration-200 hover:opacity-75 sm:mb-[10vh] sm:mt-[80px] md:px-20 md:text-3xl md:leading-[36px] lg:mt-[50px]'
          )}
          onClick={() => navigate(ERouterPaths.LEARN_MORE)}
        >
          <span>Mint & Play Now</span>
        </button>
      </div>
    </div>
  );
}
