import cn from 'classnames';
import React, { useMemo } from 'react';
import { useSearchParam } from 'react-use';
import { useAccount } from 'wagmi';

import { Button } from '@/components/common/Button';
import { targetChain } from '@/components/common/WagmiProvider';
import { EMintType, MintTypePSearchParamKey } from '@/pages/games/2048';
import { useAppStore } from '@/store/appStore';
import { allGames } from '@/utils/allGames';
import { getRarityByTokenId } from '@/utils/common';

import { awsBaseUrl } from '../GamePlay/Game';
interface IPreviewBoardProps {
  className?: string;
  tokenId: number;
  tokenIds: number[];
  txHash: string;
}

export function PreviewBoard(props: IPreviewBoardProps) {
  const { className, tokenId, tokenIds, txHash } = props;
  const mintType = useSearchParam(MintTypePSearchParamKey);
  const { setPlayMintSignature, setGameMoves } = useAppStore();

  const { score } = useAppStore();
  const { address } = useAccount();
  const item = useMemo(() => allGames[tokenId], [tokenId]);
  const chessBoard = item.slice(0, 1);
  const titleBg = item.slice(1, 2);
  const font = item.slice(2, 3);
  const frame = item.slice(3, 4);
  const awsPath = `${awsBaseUrl}`;
  const chessBoardUrl = `${awsPath}${chessBoard}_Chessboard_BG.png`;
  const frameUrl = `${awsPath}${frame}_Frame.png`;
  const font2Url = `${awsPath}${font}_Font_2.png`;
  const font4Url = `${awsPath}${font}_Font_4.png`;
  const font8Url = `${awsPath}${font}_Font_8.png`;
  const font16Url = `${awsPath}${font}_Font_16.png`;
  const font32Url = `${awsPath}${font}_Font_32.png`;
  const font64Url = `${awsPath}${font}_Font_64.png`;
  const font128Url = `${awsPath}${font}_Font_128.png`;
  const font256Url = `${awsPath}${font}_Font_256.png`;
  const font512Url = `${awsPath}${font}_Font_512.png`;
  const font1024Url = `${awsPath}${font}_Font_1024.png`;
  const font2048Url = `${awsPath}${font}_Font_2048.png`;

  const tile2Url = `${awsPath}${titleBg}_Tile_BG_2.png`;
  const tile4Url = `${awsPath}${titleBg}_Tile_BG_4.png`;
  const tile8Url = `${awsPath}${titleBg}_Tile_BG_8.png`;
  const tile16Url = `${awsPath}${titleBg}_Tile_BG_16.png`;
  const tile32Url = `${awsPath}${titleBg}_Tile_BG_32.png`;
  const tile64Url = `${awsPath}${titleBg}_Tile_BG_64.png`;
  const tile128Url = `${awsPath}${titleBg}_Tile_BG_128.png`;
  const tile256Url = `${awsPath}${titleBg}_Tile_BG_256.png`;
  const tile512Url = `${awsPath}${titleBg}_Tile_BG_512.png`;
  const tile1024Url = `${awsPath}${titleBg}_Tile_BG_1024.png`;
  const tile2048Url = `${awsPath}${titleBg}_Tile_BG_2048.png`;

  let contentAreaWidth = '';
  let contentAreaLeftOffset = '';
  let contentAreaTopOffset = '';
  let frameAspectRadio = '';
  if (frame === 'A' || frame === 'C' || frame === 'D' || frame === 'K') {
    contentAreaWidth = '97.34%';
    contentAreaLeftOffset = '1.33%';
    contentAreaTopOffset = '1.33%';
    frameAspectRadio = '1';
  } else if (frame === 'B') {
    contentAreaLeftOffset = '4.63%';
    contentAreaWidth = '91.27%';
    contentAreaTopOffset = '6.55%';
    frameAspectRadio = '0.9672';
  } else if (frame === 'E') {
    contentAreaLeftOffset = '6.31%';
    contentAreaWidth = '87.37%';
    contentAreaTopOffset = '12.92%';
    frameAspectRadio = '0.9832';
  } else if (frame === 'F') {
    contentAreaLeftOffset = '7.57%';
    contentAreaWidth = '84.21%';
    contentAreaTopOffset = '9.42%';
    frameAspectRadio = '1.0611';
  } else if (frame === 'G') {
    contentAreaWidth = '97.34%';
    contentAreaLeftOffset = '1.33%';
    contentAreaTopOffset = '4.77%';
    frameAspectRadio = '0.9651';
  } else if (frame === 'H') {
    contentAreaWidth = '86.78%';
    contentAreaLeftOffset = '11.53%';
    contentAreaTopOffset = '9.74%';
    frameAspectRadio = '1.0261';
  } else if (frame === 'I') {
    contentAreaWidth = '93.6%';
    contentAreaLeftOffset = '3.11%';
    contentAreaTopOffset = '3.1%';
    frameAspectRadio = '0.9982';
  } else if (frame === 'J') {
    contentAreaWidth = '97.34%';
    contentAreaLeftOffset = '1.33%';
    contentAreaTopOffset = '12.92%';
    frameAspectRadio = '0.8826';
  } else if (frame === 'L') {
    contentAreaWidth = '82.71%';
    contentAreaLeftOffset = '8.72%';
    contentAreaTopOffset = '8.66%';
    frameAspectRadio = '1.0509';
  }

  const tiles = [
    {
      font: font2048Url,
      tile: tile2048Url
    },
    {
      font: font8Url,
      tile: tile8Url
    },
    {
      font: font128Url,
      tile: tile128Url
    },
    {
      font: font1024Url,
      tile: tile1024Url
    },
    {
      font: font2Url,
      tile: tile2Url
    },
    {
      font: font2Url,
      tile: tile2Url
    },
    {
      font: font32Url,
      tile: tile32Url
    },
    {
      font: font64Url,
      tile: tile64Url
    },
    {
      font: font256Url,
      tile: tile256Url
    },
    {
      font: font16Url,
      tile: tile16Url
    },
    {
      font: font64Url,
      tile: tile64Url
    },
    {
      font: font4Url,
      tile: tile4Url
    },
    {
      font: font1024Url,
      tile: tile1024Url
    },
    {
      font: font16Url,
      tile: tile16Url
    },
    {
      font: font8Url,
      tile: tile8Url
    },
    {
      font: font512Url,
      tile: tile512Url
    }
  ];

  return (
    <div className={cn(className, 'mx-auto w-full max-w-[293px]')}>
      <div
        className="relative w-full"
        style={{
          aspectRatio: frameAspectRadio
        }}
      >
        {/* 背景图层 */}
        <div
          className="absolute grid aspect-square grid-cols-4 gap-x-[1.17%] gap-y-[0.98%] overflow-hidden rounded-lg p-[3.32%]"
          style={{
            backgroundImage: `url('${chessBoardUrl}')`,
            left: contentAreaLeftOffset,
            top: contentAreaTopOffset,
            width: contentAreaWidth
          }}
        ></div>
        <img src={frameUrl} className="absolute w-full" />

        {/* 内容图层 */}
        <div
          className="absolute grid aspect-square grid-cols-4 gap-x-[1.17%] gap-y-[0.98%] overflow-hidden rounded-lg p-[3.32%]"
          style={{
            left: contentAreaLeftOffset,
            top: contentAreaTopOffset,
            width: contentAreaWidth
          }}
        >
          {tiles.map((item, index) => (
            <div
              key={index}
              className="flex aspect-square w-full items-center bg-cover bg-center"
              style={{
                backgroundImage: `url('${item.tile}')`
              }}
            >
              <img src={item.font} className="mx-auto h-[23.5%]" />
            </div>
          ))}
        </div>
      </div>
      <h3 className="mb-2 mt-4 text-base leading-4 sm:text-[19px]">
        Inscription #{tokenId}
      </h3>
      <p className="mb-4 w-full font-BaiJamjuree text-xs font-medium leading-[18px] text-[#6C7B92]">
        This 2048.ink is{' '}
        <span className="font-bold leading-[18px] text-white">
          {getRarityByTokenId(tokenId)}
        </span>{' '}
        rarity,
        <br /> and was minted by{' '}
        <span className="font-bold leading-[18px] text-white">
          {address}
        </span>{' '}
        {mintType === EMintType.PlAY && (
          <span>
            with a PLAY to Mint score of{' '}
            <span className="font-bold leading-[18px] text-white">{score}</span>
          </span>
        )}
        <br /> on{' '}
        <span className="font-bold leading-[18px] text-white">
          {getCurrentTime()}
        </span>
      </p>
      <Button
        // disabled={isTestnet}
        onClick={() => {
          const tokenIndex = tokenIds.findIndex((item) => item === tokenId);
          if (tokenIndex >= 0 && txHash) {
            setGameMoves([]);
            window.open(
              `https://evm.ink/marketplace/eip155:${targetChain.id}/${txHash}:${
                tokenIndex + 1
              }`,
              '_blank'
            );
          }
        }}
        className="mx-auto mb-4 h-12 w-full sm:!text-[20px]"
      >
        View on evm.ink
      </Button>
      <p className="font-BaiJamjuree text-xs font-medium text-white">
        Please be aware that{' '}
        <span className="font-bold text-[#FF71FD]">
          it may take a few minutes for emv.ink to index this transaction
        </span>
        . If you encounter a "NOT FOUND" notice, kindly recheck it after a few
        minutes. :{`)`}
      </p>
    </div>
  );
}

function getCurrentTime(): string {
  const date = new Date();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const formattedDate = `${month}/${day}/${year} UTC`;
  return formattedDate;
}
